import Actions from 'actions/types';

const initialState = {
  isOpen: false,
};

/**
 *Auth Reducer
 * @param {*} state
 * @param {*} action
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.IS_DRAWER_OPEN:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    default:
      return state;
  }
};
