const Actions = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  CLEAR_REDUCERS: 'CLEAR_REDUCERS',
  GET_AUTH_ERRORS: 'GET_AUTH_ERRORS',
  IS_DRAWER_OPEN: 'IS_DRAWER_OPEN',
  NEW_NOTIFICATION: 'NEW_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
};

export default Actions;
