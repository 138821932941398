import React from 'react';
import PropTypes from 'prop-types';

import Styles from './textarea.module.scss';

function TextArea({
  type,
  label,
  required,
  requiredText,
  errorMessage,
  rows,
  radius,
  border,
  size,
  onChange,
  ...rest
}) {
  const handlechange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <div className={[Styles.textAreaWrap, Styles[size]].join(' ')}>
      {label && (
        <label className={[Styles.label_text]}>
          {label}
          {requiredText && <span>{requiredText}</span>}
        </label>
      )}
      <textarea
        onChange={handlechange}
        rows={rows}
        className={[
          Styles.formField,
          errorMessage && Styles.error,
          Styles[radius],
          Styles[border],
          Styles[size],
        ].join(' ')}
        {...rest}
      />

      {errorMessage && <span className={[Styles.error_text]}>{errorMessage}</span>}
    </div>
  );
}

export default TextArea;
TextArea.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  requiredText: PropTypes.string,
  errorMessage: PropTypes.string,
  rows: PropTypes.number,
  radius: PropTypes.string,
  border: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
TextArea.defaultProps = {
  radius: 'round',
  border: 'none',
  size: 'normal',
  type: '',
  label: '',
  required: false,
  requiredText: '',
  errorMessage: '',
  rows: 0,
};
