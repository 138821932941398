import React from 'react';

import loader from 'images/spinner.gif';
import Styles from './loader.module.scss';

export default function Loader() {
  return (
    <div className={Styles.loader_area}>
      <img src={loader} alt="loading..." />
    </div>
  );
}
