import React from 'react';

import { noData } from './nodata.module.scss';

const Nodata = () => (
  <div className={noData}>
    <div>
      <img src="/nothing.svg" alt="nothing" />
    </div>
  </div>
);

export default Nodata;
