import React, { useState } from 'react';
import { Tooltip } from '@acodez/tooltip';
import PropTypes from 'prop-types';

import { ClassNames, isEmpty } from 'helper';
import InfoIcon from 'images/icon-info.svg';
import Styles from './simpleselect.module.scss';

export default function SelectDrop({
  options = [],
  size,
  info,
  value,
  label,
  name,
  onChange,
  errorMessage,
  width,
  className,
  requiredText,
}) {
  const [toggle, settoggle] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [val, setval] = useState(value);

  const handleclick = () => {
    settoggle(!toggle);
  };

  const handleChange = (dt) => {
    setval(dt.label);
    onChange(name, dt?._id || dt?.key);
  };

  return (
    <div className={Styles.select_field}>
      {label && (
        <label className={[Styles.label_text]}>
          {label}
          {requiredText && <span>{requiredText}</span>}
          {!isEmpty(info) && (
            <Tooltip text={info} position="right">
              <img className={Styles.tooltipIcon} src={InfoIcon} alt="" />
            </Tooltip>
          )}
        </label>
      )}
      {/* {JSON.stringify(val)} */}
      {/* {JSON.stringify(value)} */}
      <div
        className={ClassNames([Styles.select_area, Styles[size], Styles[className]])}
        style={{ width }}
      >
        <button
          type="button"
          className={ClassNames([Styles.tab_select, errorMessage && Styles.error])}
          onClick={handleclick}
        >
          <span className={ClassNames([value ? Styles.val : Styles.placeholder])}>
            {options?.filter((val) => value === val.key)[0]?.label.toUpperCase() || 'Please Select'}
          </span>
          <div className={Styles[toggle ? 'rotate' : '']} />
        </button>
        <ul className={Styles[toggle ? 'list' : '']}>
          {options?.map((val) => (
            <li className={Styles[value?.label === val?.label ? 'active' : '']}>
              <button
                type="button"
                onClick={() => {
                  settoggle(false);
                  handleChange(val);
                }}
              >
                {val.label}
              </button>
            </li>
          ))}
          {!options && <li>No Content Here</li>}
        </ul>
      </div>
      {errorMessage && <span className={Styles.error_text}>{errorMessage}</span>}
      {toggle && (
        <button
          type="button"
          aria-label="outerdiv"
          className={Styles.outerdiv}
          onClick={() => settoggle(false)}
        />
      )}
    </div>
  );
}

SelectDrop.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  info: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  requiredText: PropTypes.bool,
};

SelectDrop.defaultProps = {
  width: '',
  info: '',
  label: '',
  errorMessage: '',
  name: '',
  size: '',
  className: '',
  requiredText: false,
};
