import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { requestFirebasePermission } from 'actions';
import Loader from 'Components/Loader';
import Nodata from 'Components/Nodata';
import Wrapper from 'container/Wrapper';
import { hasPermission } from 'helper';

/**
 *Authenticated Route function
 * @param {*} param
 */
function AuthenticatedRoute({ component: Component, permissionUrl, permissions, ...rest }) {
  const courseCount = localStorage.getItem('courseCount');
  useEffect(() => {
    if (!rest.isAuthenticated) {
      rest.history.push('/login');
    } else if (courseCount === 0) {
      rest.history.push('/courses/empty');
    }
    if (rest.isAuthenticated) {
      rest.requestFirebasePermission();
    }
  }, []);

  return rest.isAuthenticated ? (
    <>
      <div className="authcontent">
        {/* <BreadCrumb /> */}

        <Wrapper>
          {hasPermission(permissionUrl, permissions) ? (
            <Route {...rest}>
              <Suspense fallback={<Loader />}>
                <Component />
              </Suspense>
            </Route>
          ) : (
            <Nodata />
          )}
        </Wrapper>
      </div>
    </>
  ) : null;
}
// Map state to props
const mapStateToProps = (state) => ({
  ...state.authReducer,
  permissions: state?.roleConfig?.data,
  course: state.course,
});
// prop types
AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  permissionUrl: PropTypes.shape(),
  permissions: PropTypes.shape(),
  requestFirebasePermission: PropTypes.func.isRequired,
};

AuthenticatedRoute.defaultProps = {
  permissionUrl: {},
  permissions: {},
};
export default withRouter(
  connect(mapStateToProps, { requestFirebasePermission })(AuthenticatedRoute)
);
