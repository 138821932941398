import React from 'react';
import PropTypes from 'prop-types';

import { ClassNames } from 'helper';

export default function PageWrap({ size, children }) {
  return <div className={ClassNames(['pageWrap', [size]])}>{children}</div>;
}

PageWrap.propTypes = {
  children: PropTypes.element,
  size: PropTypes.string,
};

PageWrap.defaultProps = {
  children: null,
  size: '',
};
