import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import actions from 'actions';
import Actions from 'actions/types';
import InfiniteScroller from 'Components/InfiniteScroller';
import SearchField from 'Components/SearchField';
import Table from 'Components/Table';
import Text from 'Components/Typography';
import { getActions } from 'helper';
import store from 'store';
// import editicon from 'images/editactionicon.svg';
import Styles from './notification.module.scss';

function Notification({ notification, getData, history }) {
  const [listData, setlistData] = useState([]);
  const [data, setdata] = useState([]);
  const [page, setpage] = useState(1);

  useEffect(() => {
    const action = getActions('DASHBOARD_NOTIFICATION');
    getData('dashboard/notifications', action.GETONE).then((res) => {
      if (!res.error) {
        setlistData(res?.payload?.data?.data);
      }
    });
    store.dispatch({
      type: Actions.CLEAR_NOTIFICATION,
    });
  }, []);

  useEffect(() => {
    if (listData) {
      const result = listData?.map((val) => ({
        _id: val?._id,
        type: val?.type,
        content: val?.content,
        createdAt: val?.createdAt,
        students: val?.students,
        title: `${val?.students?.name} ${val?.content?.body}`,
        mediaStorageType: val?.students?.mediaStorageType,
        profileImageName: val?.students?.profileImageName,
        profileImagePath: val?.students?.profileImagePath,
      }));
      setdata(result);
    }
  }, [listData]);

  const handleViewDetails = (id, type) => {
    switch (type) {
      case 'courseEnrolled':
        history.push(`/students/${id}/course`);
        break;
      case 'newUser':
        history.push(`/students/${id}/analytics`);
        break;
      case 'review':
        history.push(`/students/${id}/reviews`);
        break;
      case 'courseSubscribed':
        history.push(`/students/${id}/subscription`);
        break;
      default:
        history.push(`/students/${id}/analytics`);
        break;
    }
  };
  const NOTIFICATION_HEADING = [
    // {
    //   label: 'Title',
    //   key: 'content.title',
    // },
    // {
    //   label: 'Content',
    //   key: 'content.body',
    // },
    // {
    //   label: 'Student',
    //   key: 'students.name',
    // },
    {
      label: 'Title',
      key: {
        image: 'profileImagePath',
        title: 'title',
        // title2: 'email',
      },
      type: 'notification',
      style: { cursor: 'pointer' },
      onClickFunction: handleViewDetails,
    },
    // {
    //   label: 'Title',
    //   key: 'title',
    // },
    {
      label: 'Date',
      key: 'createdAt',
      type: 'date',
    },
  ];

  /**
   *fetchData
   */
  const fetchData = () => {
    setpage(page + 1);
    const action = getActions('DASHBOARD_NOTIFICATION');
    getData('dashboard/notifications', action.GETONE, {
      page: page + 1,
    }).then((res) => {
      if (!res.error) {
        // setlistData(listData.concat(res?.payload?.data?.data));
        setlistData((prev) => [...prev, ...res?.payload?.data?.data]);
      }
    });
  };

  return (
    <div className="mainContent">
      <div className="pageTitleSec">
        <Text type="h2">Notifications</Text>
        <div className="pageTitleRight">
          <SearchField />
        </div>
      </div>
      <div className={Styles.question_table_block}>
        <InfiniteScroller loading={notification?.loading} dataLength={10} next={fetchData}>
          <Table headings={NOTIFICATION_HEADING} rows={data} size="medium" />
        </InfiniteScroller>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  notification: state.dashboardNotification,
});

Notification.propTypes = {
  notification: PropTypes.shape,
  getData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

Notification.defaultProps = {
  notification: {},
  history: {},
};
export default withRouter(connect(mapStateToProps, actions)(Notification));
