import { combineReducers } from 'redux';

import { getActions } from 'helper';
import authReducer from './authReducer';
import commonReducer from './commonReducer';
import notificationReducer from './notificationReducer';
import sidebarReducer from './sidebarReducer';

const getReducer = (reducer, type) => {
  const Action = getActions(type);
  return {
    [reducer]: commonReducer(Action.GETALL),
    [`${reducer}_details`]: commonReducer(Action.GETONE),
    [`${reducer}_create`]: commonReducer(Action.CREATE),
    [`${reducer}_update`]: commonReducer(Action.UPDATE),
    [`${reducer}_delete`]: commonReducer(Action.DELETE),
  };
};

const getSingleGetReducer = (reducer, type) => {
  const Action = getActions(type);
  return {
    [reducer]: commonReducer(Action.GETONE),
  };
};

const getSingleCreateReducer = (reducer, type) => {
  const Action = getActions(type);
  return {
    [reducer]: commonReducer(Action.CREATE),
  };
};

const getSingleGetAllReducer = (reducer, type) => {
  const Action = getActions(type);
  return {
    [reducer]: commonReducer(Action.GETALL),
  };
};

// const getSingleDeleteReducer = (reducer, type) => {
//   const Action = getActions(type);
//   return {
//     [reducer]: commonReducer(Action.DELETE),
//   };
// };

const getSingleUpdateReducer = (reducer, type) => {
  const Action = getActions(type);
  return {
    [reducer]: commonReducer(Action.UPDATE),
  };
};

export default combineReducers({
  authReducer,
  notificationReducer,
  ...getSingleGetReducer('studentAnalytics', 'STUDENTS_ANALYTICS'),
  ...getSingleGetReducer('studentAnalyticsHours', 'STUDENTS_ANALYTICS_HOURS'),
  ...getSingleGetReducer('studentAnalyticsCompletedCourse', 'STUDENTS_ANALYTICS_COMPLTED_COURSE'),
  ...getSingleGetReducer('studentCourseStatistics', 'STUDENTS_COURSE_STATISTICS'),
  ...getSingleGetReducer('subscriptions', 'SUBSCRIPTIONS'),
  ...getSingleGetReducer('currentPlan', 'CURRENT_PLAN'),
  ...getSingleGetReducer('newRegistrations', 'NEW_REGISTRATIONS'),
  ...getSingleGetReducer('recentExam', 'RECENT_EXAM'),
  ...getSingleGetReducer('dashboardNotification', 'DASHBOARD_NOTIFICATION'),
  ...getSingleGetReducer('summary', 'SUMMARY'),
  ...getSingleGetReducer('materialCount', 'MATERIAL_COUNT'),
  ...getSingleGetReducer('coursematerialCount', 'COURSE__MATERIAL_COUNT'),
  ...getSingleGetReducer('materialunlink', 'MATERIAL_UNLINK'),
  ...getSingleGetAllReducer('coursepreview', 'COURSE_PREVIEW'),
  ...getSingleGetAllReducer('placeholderImages', 'PLACEHOLDER_IMAGES'),
  ...getSingleUpdateReducer('coursePublish', 'COURSE_PUBLISH'),
  ...getSingleUpdateReducer('courseSaveDraft', 'COURSE_SAVE_DRAFT'),
  ...getSingleCreateReducer('sendMail', 'SEND_MAIL'),
  ...getSingleCreateReducer('feature', 'FEATURE'),
  ...getSingleCreateReducer('paid', 'PAID'),
  ...getSingleCreateReducer('sendNotification', 'SEND_NOTIFICATION'),
  ...getSingleGetReducer('enrolledCourse', 'ENROLLED_COURSE'),
  ...getSingleGetReducer('signaturePath', 'SIGNATURE_PATH'),
  ...getSingleGetReducer('courseSummary', 'COURSE_SUMMARY'),
  ...getSingleGetReducer('courseSales', 'COURSE_SALES'),
  ...getSingleGetReducer('courseEnrollments', 'COURSE_ENROLLMENTS'),
  ...getSingleGetReducer('courseStatistics', 'COURSE_STATISTICS'),
  ...getSingleGetReducer('courseStudentAnalytics', 'COURSE_STUDENT_ANALYTICS'),
  ...getSingleGetReducer('publishLiveExamRankList', 'PUBLISH_LIVE_EXAM_RANK_LIST'),
  ...getSingleCreateReducer('cancelSubscription', 'CANCEL_SUBSCRIPTION'),
  ...getSingleCreateReducer('materialDuration', 'MATERIAL_DURATION'),
  ...getReducer('configurationLanguage', 'CONFIGURATION_LANGUAGE'),
  ...getReducer('course', 'COURSES'),
  ...getReducer('coursereview', 'COURSES_REVIEW'),
  ...getReducer('student', 'STUDENTS'),
  ...getReducer('studentreview', 'STUDENTS_REVIEW'),
  ...getReducer('coursecommondata', 'COURSESCOMMONDATA'),
  ...getReducer('coursesubject', 'COURSESUBJECT'),
  ...getReducer('coursechapters', 'COURSECHAPTERS'),
  ...getReducer('courseplans', 'PLAN'),
  ...getReducer('subjectdelete', 'DELETESUBJECT'),
  ...getReducer('questionbank', 'QUESTIONBANK'),
  ...getReducer('materials', 'MATERIALS'),
  ...getReducer('coursematerials', 'COURSEMATERIALS'),
  ...getReducer('coureMaterialReview', 'COURSE_MATERIAL_REVIEW'),
  ...getReducer('profile', 'PROFILE'),
  ...getReducer('general', 'GENERAL'),
  ...getReducer('payment', 'PAYMENT'),
  ...getReducer('role', 'ROLE'),
  ...getReducer('user', 'USER'),
  ...getReducer('mocktest', 'MOCKTEST'),
  ...getReducer('liveexam', 'LIVEEXAM'),
  ...getReducer('previousExam', 'PREVIOUSEXAM'),
  ...getReducer('liveclass', 'LIVECLASS'),
  ...getReducer('reward', 'REWARD'),
  ...getReducer('smtp', 'SMTP'),
  ...getReducer('sms', 'SMS'),
  ...getReducer('perpage', 'PERPAGE'),
  ...getReducer('configuration', 'CONFIGURATION'),
  ...getReducer('permission', 'PERMISSION'),
  ...getReducer('roleConfig', 'ROLE_CONFIG'),
  ...getReducer('email', 'EMAIL'),
  ...getReducer('scheduler', 'SCHEDULER'),
  ...getReducer('cms', 'CMS'),
  ...getReducer('faq', 'FAQ'),
  ...getReducer('settings', 'SETTINGS'),
  sidebar: sidebarReducer,
});
