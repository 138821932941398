// import * as firebase from 'firebase/app';

// import 'firebase/messaging';

// const FirebaseApp = firebase.initializeApp({
//   apiKey: 'AIzaSyBn_dpF10NGFngSchD4RQzV5ZVCD59z9ac',
//   authDomain: 'learning-app-e67c8.firebaseapp.com',
//   projectId: 'learning-app-e67c8',
//   storageBucket: 'learning-app-e67c8.appspot.com',
//   messagingSenderId: '141260669942',
//   appId: '1:141260669942:web:d8c10725269ae693c37dd7',
//   measurementId: 'G-883H6LQ8R4',
// });

// // eslint-disable-next-line import/no-mutable-exports
// let messaging = null;
// if (firebase.messaging.isSupported()) {
//   messaging = FirebaseApp.messaging();

//   messaging.usePublicVapidKey(
//     // Project Settings => Cloud Messaging => Web Push certificates
//     'BFQ-pBS3hkeMX0V2eLIIJO3M5y6Izyue3ZInjw1tzsxu4cAnRvgaa2ijg6uwOgtrnUD-IHNpVhY4Dr74Rw752uA'
//   );
// }

// export { FirebaseApp, messaging };

import * as firebase from 'firebase/app';

import 'firebase/messaging';

const FirebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyBn_dpF10NGFngSchD4RQzV5ZVCD59z9ac',
  authDomain: 'learning-app-e67c8.firebaseapp.com',
  projectId: 'learning-app-e67c8',
  storageBucket: 'learning-app-e67c8.appspot.com',
  messagingSenderId: '141260669942',
  appId: '1:141260669942:web:d8c10725269ae693c37dd7',
  measurementId: 'G-883H6LQ8R4',
});

// eslint-disable-next-line import/no-mutable-exports
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = FirebaseApp.messaging();

  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    'BFQ-pBS3hkeMX0V2eLIIJO3M5y6Izyue3ZInjw1tzsxu4cAnRvgaa2ijg6uwOgtrnUD-IHNpVhY4Dr74Rw752uA'
  );
}

export { FirebaseApp, messaging };
