/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Styles from './progressbar.module.scss';

function Progressbar({ color, type, progress, corner, size, ShowProgressCount, CountPosition }) {
  return (
    <div
      className={[
        Styles.Progressbar,
        Styles[type],
        Styles[color],
        Styles[corner],
        Styles[size],
        Styles[CountPosition],
      ].join(' ')}
    >
      <div className={[Styles.Bar].join(' ')} style={{ width: `${progress}%` }} />
      {ShowProgressCount && <span className={Styles.Percent}>{progress?.toPrecision(2)}%</span>}
    </div>
  );
}

export default Progressbar;

Progressbar.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'success',
    'warning',
    'dark',
    'light',
    'info',
  ]),
  type: PropTypes.oneOf(['normal', 'animate', 'striped', 'animate-striped']),
  CountPosition: PropTypes.oneOf(['overlay', 'left', 'right']),
  corner: PropTypes.oneOf(['rounded', 'square']),
};

Progressbar.defaultProps = {
  color: 'primary',
  type: 'normal',
  size: 'md',
  corner: 'rounded',
  CountPosition: 'overlay',
};
