import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Carousel from '@brainhubeu/react-carousel';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { resetPassword } from 'actions/auth';
import Button from 'Components/Button';
import FormBuilder from 'Components/FormBuilder';
import { NEW_PASSWORD } from 'config/fields';
import { isEmpty } from 'helper';
import { validate } from 'helper/validation';
import loginPic from 'images/login-pic.png';
import logoImageLight from 'images/logolight.svg';
import Styles from './newpassword.module.scss';

import '@brainhubeu/react-carousel/lib/style.css';

function NewPassword(props) {
  const { resetPassword } = props;
  const [data, setdata] = useState({});
  const [errors, seterrors] = useState({});
  const [loading, setloading] = useState(false);

  const onformchange = (name, value) => {
    setdata({ ...data, [name]: value });
  };
  const handleSubmit = (e) => {
    const { location } = props;
    e.preventDefault();
    // eslint-disable-next-line no-debugger
    const parsed = queryString.parse(location.search);
    const { token, id } = parsed;
    const error = validate(data, NEW_PASSWORD);
    if (isEmpty(error) && data.password !== data.passwordConfirm) {
      error.passwordConfirm = "Password doesn't match";
    }
    if (isEmpty(error)) {
      setloading(true);
      resetPassword({ token, id, password: data.password }, () => setloading(false));
      //  addToast({message: 'Log In with new password', theme: 'success'});
    } else {
      seterrors(error);
    }
  };

  const getFields = () =>
    NEW_PASSWORD.map((field) =>
      field?.name === 'submit' ? { ...field, onClick: handleSubmit, loading } : field
    );

  return (
    <div>
      <div className={Styles.loginPage}>
        <div className={Styles.loginContent}>
          <div className={Styles.loginLogo}>
            <img src={logoImageLight} alt="logo" />
          </div>
          <div className={Styles.loginForm}>
            <div className={Styles.loginFormHead}>
              <h2>Create new password</h2>
              <p>Create new password for your account.</p>
            </div>
            <FormBuilder
              errors={errors}
              formdata={data}
              onChange={onformchange}
              fields={getFields()}
            />
            <div className="backToLogin">
              <Button type="button" link="/login" btnStyle="transparent">
                Login
              </Button>
            </div>
          </div>
        </div>
        <div className={Styles.loginCarousel}>
          <img className={Styles.loginLeftImage} src={loginPic} alt="login" />
          <div className={Styles.carouselWrap}>
            <Carousel dots autoPlay="3000" infinite>
              <div>
                <h2>1 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>
                  ac ornare risus justo eget nisl. Aenean elit magna, vestibulum sed quam sit amet,
                  sagittis pharetra tellus. Nunc rhoncus, nisl quis ultricies tempor
                </p>
              </div>
              <div>
                <h2>2 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>aca rhoncus, nisl quis ultricies tempor</p>
              </div>
              <div>
                <h2>3 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>
                  ac ornare risus justo eget nisl. Aenean elit magna, vestibulum sed quam sit amet,
                  sagittis pharetra tellus. Nunc rhoncus, nisl quis ultricies tempor
                </p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = () => ({});

NewPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, { resetPassword })(NewPassword));
