import React from 'react';
import PropTypes from 'prop-types';

import { ClassNames } from 'helper';
import Styles from './durationfilter.module.scss';

export default function DurationFilter({ padding, tabs, handleClick }) {
  return (
    <div className={ClassNames([Styles.filter_tab, Styles[padding]])}>
      <ul>
        {tabs.map(({ label, isactive }) => (
          <li>
            <button
              type="button"
              key={label}
              aria-label="filter"
              onClick={() => handleClick(label)}
              className={isactive ? Styles.active : ''}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
DurationFilter.propTypes = {
  padding: PropTypes.string,
  tabs: PropTypes.arrayOf().isRequired,
  handleClick: PropTypes.func.isRequired,
};

DurationFilter.defaultProps = {
  padding: '',
};
