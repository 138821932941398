import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import actions, { toggleDrawer } from 'actions';
import { getActions, hasPermission } from 'helper';
import Styles from './navlist.module.scss';

function Navlist({
  items,
  direction,
  theme,
  border,
  hoverStyle,
  getData,
  permissions,
  type,
  ...other
}) {
  const action = getActions('ROLE_CONFIG');
  useEffect(() => {
    getData('configuration/userpermissions', action.GETALL);
    if (theme !== 'dark') {
      other.toggleDrawer();
    }
    return () => {
      if (theme !== 'dark') {
        other.toggleDrawer();
      }
    };
  }, []);

  return (
    <div>
      <ul
        className={[
          Styles.NavListWrap,
          Styles[direction],
          Styles[theme],
          Styles[border],
          Styles[hoverStyle],
        ].join(' ')}
      >
        {items.map((item) => (
          <>
            {hasPermission(item.permissionUrl, permissions) ||
            (hasPermission(item.permissionUrl, permissions) === false &&
              item?.permissionUrl?.type === 'disabled') ? (
              <NavItem
                key={item.link}
                permissionUrl={
                  hasPermission(item.permissionUrl, permissions) === false &&
                  item?.permissionUrl?.type === 'disabled'
                }
                link={item.link}
                label={item.label}
                imageSrc={item.imageSrc}
                corner={item.corner}
              />
            ) : null}
          </>
        ))}
      </ul>
    </div>
  );
}

function NavItem({ link, label, imageSrc, corner, permissionUrl }) {
  return (
    <li key={link} className={[Styles.navitem, permissionUrl && Styles.disable].join(' ')}>
      <NavLink
        activeClassName={Styles.current}
        to={link}
        title={label}
        className={[Styles.navitemLink, Styles[corner]].join(' ')}
      >
        {imageSrc && <img src={imageSrc} alt="" />}
        {label && <span>{label}</span>}
      </NavLink>
    </li>
  );
}

const mapStateToProps = (state) => ({
  isOpen: state.sidebar?.isOpen,
  permissions: state.roleConfig.data,
});
export default connect(mapStateToProps, { toggleDrawer, ...actions })(Navlist);
Navlist.propTypes = {
  direction: PropTypes.string,
  theme: PropTypes.string,
  border: PropTypes.string,
  hoverStyle: PropTypes.string,
  mode: PropTypes.string,
  type: PropTypes.string,
  items: PropTypes.arrayOf().isRequired,
  getData: PropTypes.func.isRequired,
  permissions: PropTypes.shape.isRequired,
};
NavItem.propTypes = {
  corner: PropTypes.string,
  link: PropTypes.string,
  label: PropTypes.string,
  imageSrc: PropTypes.string,
  permissionUrl: PropTypes.bool,
};

Navlist.defaultProps = {
  direction: 'vertical',
  theme: 'dark',
  border: 'blank',
  hoverStyle: 'button',
  mode: 'xs',
  type: 'outerNavBar',
};

NavItem.defaultProps = {
  corner: 'round',
  link: '',
  label: '',
  imageSrc: '',
  permissionUrl: false,
};
