import { isEmpty } from 'helper';

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email)?.toLowerCase());
}

function isURL(url) {
  // let regEx =  /(http|https|ftp|):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\  /]))?/;
  const regEx =
    /^((https?|http?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return regEx.test(url);
}

/**
 *For set focus
 * @param {} validatedErrors
 */
export const setFocus = (validatedErrors) => {
  if (isEmpty(validatedErrors)) return true;
  const errorKey = Object.keys(validatedErrors)[0];
  const name =
    typeof validatedErrors[errorKey] === 'string'
      ? errorKey
      : Object.keys(validatedErrors[errorKey])[0];
  const focused = document.querySelector(`[name = ${name}]`);
  if (focused && focused.type === 'hidden') {
    focused.setAttribute('type', 'text');
    focused.focus();
    focused.setAttribute('type', 'hidden');
  } else if (focused) focused.focus();
  return true;
};

/**
 *
 * @param {*} data
 */
// eslint-disable-next-line import/prefer-default-export
export const validate = (data, fields) => {
  const errors = {};
  fields.forEach((field) => {
    if (
      (!data?.[field.name] && field.required) ||
      (typeof data?.[field.name] === 'string' &&
        !data?.[field.name]?.replace(/\s/g, '')?.length &&
        field.required)
    ) {
      errors[field.name] = `The ${field.label?.toLowerCase()} can't be empty`;
    } else if (
      ['email', 'replyTo', 'from', 'to', 'cc', 'bcc', 'fromEmail', 'assignedUserMail'].includes(
        field.name
      ) &&
      data?.[field.name]
    ) {
      if (!validateEmail(data?.[field.name])) {
        errors[field.name] = `Please enter a valid email address`;
      }
    } else if (field.name === 'mobile') {
      if (!/^[0-9]+$/.test(data?.mobile)) {
        errors[field.name] = `Phone number must be numbers only`;
      } else if (data?.mobile.length !== 10) {
        errors[field.name] = `Phone number must be 10 characters long`;
      }
    } else if (field.name === 'website' && data?.[field.name]) {
      if (!isURL(data?.website)) {
        errors[field.name] = `Please enter a valid website address`;
      }
    } else if (
      (field.name === 'question' || field.name === 'explanation') &&
      data?.[field.name] &&
      !data?.[field.name]
        ?.replace(/<(.|\n)*?>/g, '')
        ?.replaceAll('&nbsp;', '')
        ?.replace(/\s/g, '')?.length &&
      field.required
    ) {
      errors[field.name] = `The ${field.label?.toLowerCase()} can't be empty`;
    }
  });
  setFocus(errors);
  return errors;
};
