import axios from 'axios';
import jwtDecode from 'jwt-decode';
import setAuthToken from 'setAuthToken';

import Actions from './types';

export const setCurrentUser = (decoded) => ({
  type: Actions.SET_CURRENT_USER,
  payload: decoded,
});
export const loginUser =
  (user, callback = () => {}) =>
  (dispatch) => {
    axios
      .post('/auth/login', user)
      .then((res) => {
        const { acessToken, courseCount } = res.data.data;
        localStorage.setItem('jwtToken', acessToken);
        localStorage.setItem('courseCount', courseCount);
        setAuthToken(acessToken);
        const decoded = jwtDecode(acessToken);
        decoded.courseLength = res.data.data.courseCount;
        dispatch(setCurrentUser(decoded));
      })
      .catch((err) => {
        callback(err.response.data.error);
        dispatch({
          type: Actions.GET_AUTH_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  window.location = '/login';
};

export const resetPassword =
  ({ token, id, password }, callback = () => {}) =>
  (dispatch) => {
    axios
      .post('/auth/reset-password', { token, id, password })
      .then(() => {
        window.location = '/login';
        callback();
      })
      .catch((err) => {
        dispatch({
          type: Actions.GET_AUTH_ERRORS,
          payload: err.response?.data,
        });
      });
  };

export const forgetPassword =
  (email, callback = () => {}) =>
  (dispatch) => {
    axios
      .post(`/auth/forgot-password`, { email })
      .then(() => {
        window.location = '/login';
      })
      .catch((err) => {
        callback(err.response.data.error);
        dispatch({
          type: Actions.GET_AUTH_ERRORS,
          payload: err.response?.data,
        });
      });
  };

export const setNewUser =
  ({ token, id, password, email }, callback = () => {}) =>
  (dispatch) => {
    axios
      .post('/auth/newuser-setpassword', { token, id, password, email })
      .then(() => {
        window.location = '/login';
      })
      .catch((err) => {
        callback(err.response.data.error);
        dispatch({
          type: Actions.GET_AUTH_ERRORS,
          payload: err.response?.data,
        });
      });
  };
