import { useEffect, useState } from 'react';
import localForage from 'localforage';

export default function useLocalForge(key, initialValue = null) {
  const [storedValue, setStoredValue] = useState(initialValue);
  useEffect(() => {
    (async () => {
      try {
        const value = await localForage.getItem(key);
        setStoredValue(value);
      } catch (err) {
        return initialValue;
      }
    })();
  }, [key]);
  return [storedValue];
}
