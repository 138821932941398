import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import MyErrorBoundary from 'Components/MyErrorBoundary';
import { ToastContextProvider } from 'Components/ToastContextProvider';
import store from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import ToastContextProvider from 'Components/ToastContextProvider';
import * as serviceWorker from './serviceWorker';

import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ToastContextProvider position="bottom_center">
          <MyErrorBoundary>
            <App />
          </MyErrorBoundary>
        </ToastContextProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
