import React from 'react';
import PropTypes from 'prop-types';

import { switchContainer } from './switch.module.scss';

const Switch = ({ isChecked, onChange, name, className, label }) => {
  const handleToggle = () => {
    onChange(name, !isChecked);
  };
  return (
    <div className={[switchContainer, className || ''].join(' ')}>
      <label>{label}</label>
      <input checked={isChecked} onChange={handleToggle} type="checkbox" />
      <div>
        <div />
      </div>
    </div>
  );
};
Switch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
};

Switch.defaultProps = {
  className: '',
};

export default Switch;
