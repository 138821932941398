import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import actions from 'actions';
import Dashboard from './Dashboard';
import Notification from './Notifications';

function Media() {
  return (
    <>
      <Switch>
        <Route path="/dashboard" exact>
          <Dashboard />
        </Route>
        <Route path="/dashboard/notification">
          <Notification />
        </Route>
        <Redirect to="/dashboard" exact />
      </Switch>
    </>
  );
}

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, actions)(Media));
