import React from 'react';
import PropTypes from 'prop-types';

import Styles from './block.module.scss';

function Block({ children, size, width, height, border, className }) {
  return (
    <div
      className={[
        Styles.block,
        Styles[size],
        Styles[width],
        Styles[border],
        Styles[height],
        className,
      ].join(' ')}
    >
      {children}
    </div>
  );
}
Block.propTypes = {
  children: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  width: PropTypes.number,
  height: PropTypes.number,
  border: PropTypes.number,
  className: PropTypes.string,
};
Block.defaultProps = {
  children: '',
  size: 'md',
  width: 10,
  border: 10,
  height: 10,
  className: '',
};

export default Block;
