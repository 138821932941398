import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Carousel from '@brainhubeu/react-carousel';
import PropTypes from 'prop-types';

import { loginUser } from 'actions/auth';
import FormBuilder from 'Components/FormBuilder';
import { LOGIN_FIELDS } from 'config/fields';
import { emailTrim, isEmpty } from 'helper';
import { validate } from 'helper/validation';
import loginPic from 'images/login-pic.png';
import logoImageLight from 'images/logolight.svg';
import Styles from './login.module.scss';

import '@brainhubeu/react-carousel/lib/style.css';

function Login(props) {
  const { isAuthenticated, user, history, loginUser } = props;
  const [logindata, setlogindata] = useState({});
  const [errors, seterrors] = useState({});
  // const [recaptcha, setrecaptcha] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      if (user.courseLength === 0) {
        history.push('/courses');
      } else {
        history.push('/dashboard');
      }
      //  addToast({message: 'Successfully updated', theme: 'success'});
    }
  }, [props]);

  const onChange = (name, value) => {
    setlogindata({ ...logindata, [name]: value });
    // if (name === 'captcha') {
    //   setrecaptcha(value);
    // }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const error = validate(logindata, LOGIN_FIELDS);
    seterrors(error);
    if (isEmpty(error)) {
      loginUser({ ...logindata, email: emailTrim(logindata.email) }, (err) => {
        seterrors(err);
      });
    }
  };

  const getFields = () =>
    LOGIN_FIELDS.map((field) =>
      field?.name === 'submit'
        ? { ...field, onClick: onSubmit, loading: loginUser?.data?.loading }
        : field
    );

  return (
    <div>
      <div className={Styles.loginPage}>
        <div className={Styles.loginContent}>
          <div className={Styles.loginLogo}>
            <img src={logoImageLight} alt="logo" />
          </div>
          <div className={Styles.loginForm}>
            <div className={Styles.loginFormHead}>
              <h2>Welcome back!</h2>
              <p>Enter your details below to continue.</p>
            </div>
            <FormBuilder
              errors={errors}
              formdata={logindata}
              onChange={onChange}
              fields={getFields()}
            />
          </div>
        </div>
        <div className={Styles.loginCarousel}>
          <img className={Styles.loginLeftImage} src={loginPic} alt="login" />
          <div className={Styles.carouselWrap}>
            <Carousel dots autoPlay="3000" infinite>
              <div>
                <h2>1 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>
                  ac ornare risus justo eget nisl. Aenean elit magna, vestibulum sed quam sit amet,
                  sagittis pharetra tellus. Nunc rhoncus, nisl quis ultricies tempor
                </p>
              </div>
              <div>
                <h2>2 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>aca rhoncus, nisl quis ultricies tempor</p>
              </div>
              <div>
                <h2>3 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>
                  ac ornare risus justo eget nisl. Aenean elit magna, vestibulum sed quam sit amet,
                  sagittis pharetra tellus. Nunc rhoncus, nisl quis ultricies tempor
                </p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  ...state.authReducer,
});
Login.propTypes = {
  isAuthenticated: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape.isRequired,
};

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
  })(Login)
);
