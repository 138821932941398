const initialState = {
  data: null,
  loading: false,
  error: null,
  metaData: {},
};

/**
 * Get Data Reducer
 * @param {*} state
 * @param {*} action
 */
export default function commonReducer(TYPE) {
  return (state = initialState, action) => {
    const SUCCESS = `${TYPE}_SUCCESS`;
    const FAIL = `${TYPE}_FAIL`;
    switch (action.type) {
      case TYPE:
        return { ...state, loading: true };
      case SUCCESS:
        return {
          ...state,
          loading: false,
          error: action?.payload,
          data: action.payload?.data?.data,
          metaData: action.payload?.data?.paging,
        };
      case FAIL:
        return {
          ...state,
          loading: false,
          error: action?.error?.response?.data,
        };
      case 'CLEAR_REDUCERS':
        return initialState;
      default:
        return state;
    }
  };
}
