/* eslint-disable no-dupe-keys */
import React from 'react';
import PropTypes from 'prop-types';

import Currency from 'Components/Currency';
import plusicon from 'images/addicon.svg';

export const COURSE_ADD_INFORMATICS = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    placeholder: 'Title',
  },
  {
    name: 'subTitle',
    label: 'Subtitle',
    type: 'text',
    required: true,
    placeholder: 'Sub title',
  },
  {
    name: 'courseLanguage',
    label: 'Language',
    type: 'selectdrop',
    required: true,
    extraButton: {
      label: 'Add New',
      icon: plusicon,
    },
    placeholder: 'Choose a language',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    required: true,
    placeholder: 'Description',
    rows: 5,
  },
];
export const QUESTION_ADD_LIVE_EXAM = [
  {
    name: 'question',
    label: 'Question',
    type: 'text',
    required: true,
    placeholder: 'Question',
  },
  {
    name: 'explanation',
    label: 'explanation',
    type: 'text',
    required: true,
    placeholder: 'explanation',
  },
];

export const QUESTION_LIST_LIVE_EXAM = [
  {
    label: 'Question',
    type: 'html',
    key: 'question',
  },
  {
    label: 'Date added',
    type: 'date',
    key: 'createdAt',
  },
];

export const LIVE_EXAM_LIBRARY = [
  {
    label: 'Question',
    key: 'question',
    type: 'html',
  },
  {
    label: 'Category',
    key: 'subject',
    type: 'html',
  },
];
export const QUESTION_ADD_FIELDS = [
  {
    name: 'courseId',
    label: 'CourseId',
    type: 'text',
    required: true,
    placeholder: 'CourseId',
  },
  {
    name: 'subjectId',
    label: 'subjectId',
    type: 'text',
    required: true,
    placeholder: 'subjectId',
  },
  {
    name: 'question',
    label: 'Question',
    type: 'text',
    required: true,
    placeholder: 'Option 1',
  },
  {
    name: 'explanation',
    label: 'explanation',
    type: 'text',
    required: true,
    placeholder: 'explanation',
  },
];
export const ADD_SUBJECT_DATA = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    placeholder: 'Title',
  },
  {
    name: 'subTitle',
    label: 'Subtitle',
    type: 'textarea',
    required: true,
    placeholder: 'Subtitle',
    rows: 3,
  },
];

export const QUESTION_BULK_ADD = [
  {
    name: 'courseId',
    label: 'CourseId',
    type: 'text',
    required: true,
    placeholder: 'CourseId',
  },
  {
    name: 'subjectId',
    label: 'subjectId',
    type: 'text',
    required: true,
    placeholder: 'subjectId',
  },
];
export const ADD_PLANS = [
  {
    name: 'duration',
    label: 'Duration',
    type: 'select',
    info: 'The duration of this particular plan',
    required: true,
    placeholder: 'Duration',
    options: [
      { label: '6 Months', key: 6, value: 6 },
      { key: 3, value: 3, label: '3 Months' },
    ],
  },
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    required: true,
    placeholder: 'Name',
  },
  // {
  //   name: 'type',
  //   label: 'Plan Type',
  //   type: 'text',
  //   required: true,
  //   placeholder: 'Plan Type',
  // },
  // {
  //   name: 'description',
  //   label: 'description',
  //   type: 'textarea',
  //   required: false,
  //   placeholder: 'Description',
  // },
  {
    name: 'price',
    label: 'Amount',
    type: 'number',
    min: 0,
    required: true,
    placeholder: 'Amount',
  },
  {
    name: 'discount',
    label: 'Discount (%)',
    min: 0,
    max: 100,
    type: 'number',
    required: true,
    placeholder: 'Discount (%)',
  },
  {
    name: 'finalAmount',
    label: 'Final Amount',
    min: 0,
    type: 'number',
    disabled: true,
    required: false,
    placeholder: 'Final Amount',
  },
  {
    name: 'expiredOn',
    label: 'Expires on',
    type: 'date',
    required: true,
    info: 'The plan will expire on this date',
    placeholder: 'Expires on',
  },
];

export const ADD_EXTRAS = [
  {
    name: 'cover',
    type: 'file',
    required: false,
    placeholder: 'Upload course Image',
  },
  {
    name: 'Type',
    label: 'Type',
    type: 'dropdown',
    required: false,
    placeholder: 'Select Type',
    options: [{ value: 'Type 1' }, { value: 'Type 2' }, { value: 'Type 3' }],
  },
  {
    name: 'Title',
    label: 'Title',
    type: 'text',
    required: false,
    placeholder: 'Title',
  },
  {
    name: 'SubTitle',
    label: 'Sub Title',
    type: 'text',
    required: false,
    placeholder: 'Sub Title',
  },
  {
    name: 'description',
    label: 'description',
    type: 'textarea',
    required: false,
    placeholder: 'Description',
  },
  {
    name: 'Media Type',
    label: 'Media Type',
    type: 'dropdown',
    required: false,
    placeholder: 'Select Media Type',
    options: [{ value: 'Type 1' }, { value: 'Type 2' }, { value: 'Type 3' }],
  },
  {
    name: 'material',
    type: 'file',
    required: false,
    label: 'Upload material',
    placeholder: 'Drop video, audio, PDF, or other file(s) here or click to choose files',
  },
];

export const CMS_EDIT = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    placeholder: 'Title',
    // required: false,
    disabled: true,
  },
  {
    name: 'content',
    label: 'Content',
    type: 'editor',
    placeholder: 'Content',
  },
];

export const FAQ_EDIT = [
  {
    name: 'question',
    label: 'Question',
    type: 'textarea',
    placeholder: 'Question',
    // required: false,
    disabled: true,
  },
  {
    name: 'answer',
    label: 'Answer',
    type: 'editor',
    placeholder: 'Answer',
  },
];

export const EDIT_TEMPLATE = [
  {
    name: 'fromUser',
    label: 'From Name',
    type: 'text',
    required: true,
    placeholder: 'From Name',
  },
  {
    name: 'fromEmail',
    label: 'From Email',
    type: 'text',
    required: true,
    placeholder: 'From Email',
  },
  // {
  //   name: 'to',
  //   label: 'To',
  //   type: 'text',
  //   placeholder: 'To',
  // },
  {
    name: 'cc',
    label: 'Cc',
    type: 'text',
    placeholder: 'Cc',
  },
  {
    name: 'bcc',
    label: 'Bcc',
    type: 'text',
    placeholder: 'Bcc',
  },
  {
    name: 'subject',
    label: 'Subject',
    type: 'text',
    required: true,
    placeholder: 'Subject',
  },
  {
    name: 'content',
    label: 'Content',
    type: 'editor',
    required: true,
    placeholder: 'Content',
  },
];

export const EDIT_SMS = [
  {
    name: 'accountSid',
    label: 'account Sid',
    type: 'text',
    required: true,
    placeholder: 'accountSid',
  },
  {
    name: 'authToken',
    label: 'auth Token',
    type: 'text',
    required: true,
    placeholder: 'authToken',
  },
  {
    name: 'fromMobile',
    label: 'from Mobile',
    type: 'text',
    required: true,
    placeholder: 'from Mobile',
  },
];
export const ADD_TEMPLATE = [
  {
    name: 'key',
    label: 'Key',
    type: 'text',
    required: true,
    placeholder: 'Key',
  },
  {
    name: 'fromUser',
    label: 'From Name',
    type: 'text',
    required: true,
    placeholder: 'From Name',
  },
  {
    name: 'fromEmail',
    label: 'From Email',
    type: 'text',
    required: true,
    placeholder: 'From Email',
  },
  // {
  //   name: 'to',
  //   label: 'To',
  //   type: 'text',
  //   placeholder: 'To',
  // },
  {
    name: 'cc',
    label: 'Cc',
    type: 'text',
    placeholder: 'Cc',
  },
  {
    name: 'bcc',
    label: 'Bcc',
    type: 'text',
    placeholder: 'Bcc',
  },
  {
    name: 'subject',
    label: 'Subject',
    type: 'text',
    required: true,
    placeholder: 'Subject',
  },
  {
    name: 'content',
    label: 'Content',
    type: 'editor',
    required: true,
    placeholder: 'Content',
  },
];
export const ADD_LIVE_EXAM = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    placeholder: 'Title',
  },
  {
    name: 'date',
    label: 'Date',
    type: 'date',
    required: true,
    placeholder: 'Date',
  },
  {
    name: 'time',
    label: 'Start Time',
    type: 'time',
    required: true,
    placeholder: 'Start Time',
  },
  {
    name: 'durationInMinutes',
    label: 'Duration in minutes',
    type: 'number',
    required: true,
    placeholder: 'Duration in minutes',
  },
  {
    name: 'totalQuestions',
    label: 'Total questions',
    type: 'number',
    required: true,
    placeholder: 'Total questions',
  },
  {
    name: 'subjectId',
    label: 'Subject',
    type: 'dropdown',
    options: [{ value: 'Type 1' }, { value: 'Type 2' }, { value: 'Type 3' }],
    required: true,
    placeholder: 'Subject',
  },
  {
    name: 'courseId',
    label: 'Course',
    type: 'dropdown',
    options: [{ value: 'Type 1' }, { value: 'Type 2' }, { value: 'Type 3' }],
    required: true,
    placeholder: 'Course',
  },
  {
    name: 'negativeMark',
    label: 'Neagative mark',
    type: 'number',
    required: true,
    placeholder: 'Neagative mark',
  },
];

export const EDIT_LIVE_EXAM = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    placeholder: 'Title',
  },
  {
    name: 'date',
    label: 'Date',
    type: 'date',
    required: true,
    placeholder: 'Date',
  },
  {
    name: 'startTime',
    label: 'Start Time',
    type: 'time',
    required: true,
    placeholder: 'Start Time',
  },
  {
    name: 'durationInMinutes',
    label: 'Duration in minutes',
    type: 'number',
    required: true,
    placeholder: 'Duration in minutes',
  },
  {
    name: 'totalQuestions',
    label: 'Total questions',
    type: 'number',
    required: true,
    placeholder: 'Total questions',
  },
  {
    name: 'subjectId',
    label: 'Subject',
    type: 'dropdown',
    options: [{ value: 'Type 1' }, { value: 'Type 2' }, { value: 'Type 3' }],
    required: true,
    placeholder: 'Subject',
  },
  {
    name: 'courseId',
    label: 'Course',
    type: 'dropdown',
    options: [{ value: 'Type 1' }, { value: 'Type 2' }, { value: 'Type 3' }],
    required: true,
    placeholder: 'Course',
  },
  {
    name: 'negativeMark',
    label: 'Neagative mark',
    type: 'number',
    required: true,
    placeholder: 'Neagative mark',
  },
];

export const ADD_LIVE_CLASS = [
  {
    name: 'courseId',
    label: 'Course',
    type: 'select',
    options: [],
    required: true,
    placeholder: 'Course',
  },
  {
    name: 'subjectId',
    label: 'Subject',
    type: 'select',
    options: [],
    required: true,
    placeholder: 'Subject',
  },
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    placeholder: 'Title',
  },
  {
    name: 'assignedUserMail',
    label: 'Host mail',
    type: 'email',
    required: true,
    placeholder: 'Host mail',
  },
  {
    name: 'assignedTo',
    label: 'Host name',
    type: 'text',
    required: true,
    placeholder: 'Host name',
  },
  {
    name: 'assignedUserPhoneNumber',
    label: 'Host phone number',
    type: 'text',
    // pattern: 'd*',
    maxlength: '4',
    required: true,
    placeholder: 'Host phone number',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    required: true,
    placeholder: 'Description',
  },
  {
    name: 'date',
    label: 'Date',
    type: 'date',
    required: true,
    placeholder: 'Date',
  },
  {
    name: 'startTime',
    label: 'Start Time',
    type: 'time',
    required: true,
    placeholder: 'Start Time',
  },
  {
    name: 'endTime',
    label: 'End Time',
    type: 'time',
    required: true,
    placeholder: 'End Time',
  },
  {
    name: 'placeholderImage',
    label: 'Placeholder Image',
    type: 'image',
    required: true,
  },
  // {
  //   name: 'durationInMinutes',
  //   label: 'Duration in minutes',
  //   type: 'number',
  //   max: 100,
  //   required: true,
  //   placeholder: 'Duration in minutes',
  // },
];
export const EDIT_LIVE_CLASS = [
  {
    name: 'courseId',
    label: 'Course',
    type: 'select',
    options: [],
    required: true,
    placeholder: 'Course',
  },
  {
    name: 'subjectId',
    label: 'Subject',
    type: 'select',
    options: [],
    required: true,
    placeholder: 'Subject',
  },
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    placeholder: 'Title',
  },
  {
    name: 'assignedUserMail',
    label: 'Host mail',
    type: 'email',
    required: true,
    placeholder: 'Host mail',
  },
  {
    name: 'assignedTo',
    label: 'Host name',
    type: 'text',
    required: true,
    placeholder: 'Host name',
  },
  {
    name: 'assignedUserPhoneNumber',
    label: 'Host phone number',
    type: 'number',
    required: true,
    placeholder: 'Host phone number',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    required: true,
    placeholder: 'Description',
  },
  {
    name: 'date',
    label: 'Date',
    type: 'date',
    required: true,
    placeholder: 'Date',
  },
  {
    name: 'startTime',
    label: 'Start Time',
    type: 'time',
    required: true,
    placeholder: 'Start Time',
  },
  {
    name: 'endTime',
    label: 'End Time',
    type: 'time',
    required: true,
    placeholder: 'End Time',
  },
  {
    name: 'placeholderImage',
    label: 'Plcaholder mage',
    type: 'image',
    required: false,
  },
  // {
  //   name: 'durationInMinutes',
  //   label: 'Duration in minutes',
  //   type: 'number',
  //   max: 100,
  //   required: true,
  //   placeholder: 'Duration in minutes',
  // },
];

export const PREVIOUS_EXAM_HEADING = [
  {
    label: 'Year of exam',
    key: 'yearOfExam',
  },
  {
    label: 'NUMBER OF QUESTIONS',
    key: 'totalQuestions',
  },
  {
    label: 'Added on',
    key: 'createdAt',
  },
];

export const ADD_PREVIOUS_EXAM = [
  {
    name: 'yearOfExam',
    label: 'Year',
    type: 'number',
    required: true,
    placeholder: 'Year',
  },
  {
    name: 'totalQuestions',
    label: 'Total questions',
    type: 'number',
    required: true,
    placeholder: 'Total questions',
  },
  {
    name: 'subjectId',
    label: 'Subject',
    type: 'select',
    options: [],
    required: true,
    placeholder: 'Subject',
  },

  {
    name: 'courseId',
    label: 'Course',
    type: 'select',
    options: [],
    required: true,
    placeholder: 'Course',
  },
];

export const QUESTION_BANK_FILTER = [
  {
    label: 'Question',
    placeholder: 'Input Field',
    type: 'text',
    name: 'question',
  },
  {
    label: 'Date Picker',
    name: 'createdAt',
    type: 'date',
    minDate: new Date(1950, 6, 24),
    maxDate: new Date(),
  },
  {
    name: 'courseId',
    label: 'Courses',
    url: 'course',
    type: 'dropdownOptions',
  },
  {
    name: 'subjectId',
    label: 'Subjects',
    url: 'subject',
    type: 'dropdownOptions',
    courseId: '',
  },
];

export const LIVE_QUESTION_FILTER = [
  {
    label: 'Input Field',
    placeholder: 'Input Field',
    type: 'text',
    name: 'question',
  },
  {
    label: 'Date Picker',
    name: 'createdAt',
    type: 'date',
    minDate: new Date(1950, 6, 24),
    maxDate: new Date(),
  },
];
export const EDITOR_OPTIONS = {
  height: 100,
  buttonList: [
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'image', 'align'],
  ],
};
export const QUESTION_BANK_HEADING = [
  {
    label: 'Question',
    key: 'question',
    type: 'html',
  },
  {
    label: 'Explanation',
    type: 'html',
    key: 'explanation',
  },
  {
    label: 'Subject',
    key: 'subject.title',
  },
  // {
  //   label: 'Course',
  //   key: 'course.title',
  // },
  {
    label: 'Date Added',
    key: 'updatedAt',
    type: 'date',
  },
];

export const SETTINGS = {
  GENERAL: [
    {
      name: 'businessName',
      label: 'Business Name',
      type: 'text',
      required: true,
      placeholder: 'Business Name',
    },
    {
      name: 'website',
      label: 'Website',
      type: 'text',
      required: false,
      placeholder: 'Website',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      required: true,
      placeholder: 'Email',
    },
    {
      name: 'contactNumber',
      label: 'Contact Number',
      type: 'phone',
      required: true,
      placeholder: 'Contact Number',
    },
    {
      name: 'currency',
      label: 'Preferred Currecy',
      type: 'select',
      required: true,
      placeholder: 'Preferred Currecy',
      options: [
        { value: 'dollar', label: 'DOLLAR', key: 'dollar' },
        { value: 'indianRupee', label: 'INDIAN RUPEE', key: 'indianRupee' },
        { value: 'euro', label: 'EURO', key: 'euro' },
      ],
    },
    {
      name: 'address',
      label: 'Address',
      type: 'textarea',
      required: true,
      placeholder: 'Address',
      rows: 3,
      maxlength: 180,
    },
  ],
  REWARD: [
    {
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      name: 'point',
      label: 'Points',
      required: true,
    },
    {
      name: 'description',
      label: 'Description',
      required: true,
    },
    {
      name: 'description',
      label: 'Description',
      required: true,
    },
    {
      name: 'minimumRedeemablevalue',
      label: 'Minimum Redeemable Value',
      required: true,
    },
    {
      name: 'maximumRedeemablevalue',
      label: 'Maximum Redeemable Value',
      required: true,
    },
  ],

  SENDGRID: [
    {
      name: 'type',
      required: true,
      label: 'Type',
    },
    {
      name: 'apiKey',
      required: true,
      label: 'Api Key',
    },
  ],
  SMTP: [
    {
      name: 'type',
      required: true,
      label: 'Type',
    },
    {
      name: 'host',
      required: true,
      label: 'Host',
    },
    {
      name: 'port',
      required: true,
      label: 'Port',
    },
    {
      name: 'email',
      required: 'required',
      label: 'User',
    },
    {
      name: 'password',
      required: true,
      label: 'Password',
    },
  ],
  PERPAGE: [
    {
      name: 'perpage',
      required: true,
      label: 'Perpage',
    },
  ],
  TEST: [
    {
      name: 'negativeMark',
      label: 'Negative Mark',
      type: 'number',
      placeholder: 'Negative Mark',
      required: true,
      NumberMax: 5,
    },
    {
      name: 'totalQuestions',
      label: 'Total Questions',
      type: 'number',
      required: 'required',
      placeholder: 'Total Questions',
      required: true,
    },
    {
      name: 'totalDuration',
      label: 'Total Duration (in minutes)',
      type: 'number',
      required: 'required',
      placeholder: 'Total Duration',
      required: true,
    },
  ],
  MOCK_TEST: [
    {
      name: 'negativeMark',
      label: 'Negative Mark',
      type: 'number',
      placeholder: 'Negative Mark',
      required: true,
      NumberMax: 5,
    },
    {
      name: 'totalQuestions',
      label: 'Total Questions',
      type: 'number',
      required: 'required',
      placeholder: 'Total Questions',
      required: true,
    },
    {
      name: 'totalDuration',
      label: 'Total Duration (in minutes)',
      type: 'number',
      required: 'required',
      placeholder: 'Total Duration',
      required: true,
    },
  ],
  TEST_COURSEWISE: [
    {
      name: 'totalQuestionsCourseWise',
      label: 'Total Questions',
      type: 'text',
      placeholder: 'Total Questions',
      required: true,
    },
    {
      name: 'durationPerQuestionCourseWise',
      label: 'Duration Per Question',
      type: 'text',
      required: 'required',
      placeholder: 'Duration Per Question',
      required: true,
    },
    {
      name: 'categorywiseLimit',
      label: 'Category Wise Limit',
      type: 'text',
      required: 'required',
      placeholder: 'Category Wise Limit',
      required: true,
    },
    {
      name: 'negativeMarkCourseWise',
      label: 'Negative Mark',
      type: 'text',
      required: 'required',
      placeholder: 'Negative Mark',
      required: true,
    },
  ],
  PROFILE: [
    {
      name: 'name',
      label: 'Name',
      required: true,
    },
    {
      name: 'email',
      label: 'Email Address',
      required: true,
    },
  ],
  PROFILE_PASSSWORD: [
    {
      name: 'oldPassword',
      label: 'Current Password',
      required: true,
    },
    {
      name: 'password',
      label: 'New Password',
      required: true,
    },
  ],
  SMS: [
    {
      name: 'accountSid',
      label: 'Account Sid',
      required: true,
    },
    {
      name: 'authToken',
      label: 'Auth Token',
      required: true,
    },
    {
      name: 'fromMobile',
      label: 'From Mobile',
      required: true,
    },
  ],
};

export const PAYPAL_FIELDS = [
  {
    name: 'publicKey',
    label: 'Publi Key',
    type: 'text',
    required: true,
    placeholder: 'Paypal Public key',
  },
  {
    name: 'secretKey',
    label: 'Secrete Key',
    type: 'text',
    required: true,
    placeholder: 'Paypal Secrete key',
  },
];
export const MOCK_TEST_CONFIG = [
  {
    name: 'negativeMark',
    label: 'Negative Mark',
    required: true,
    type: 'text',
    placeholder: 'Negative Mark',
  },
  {
    name: 'totalQuestions',
    label: 'Total Questions',
    type: 'text',
    required: true,
    placeholder: 'Total Questions',
  },
  {
    name: 'durationPerQuestion',
    label: 'Duration Per Question',
    type: 'text',
    required: true,
    placeholder: 'Duration Per Question',
  },
];

export const COURSEWISE_LIVETEST_CONFIG = [
  {
    name: 'totalquestions',
    label: 'Total Questions',
    required: true,
    type: 'text',
    placeholder: 'Total Questions',
  },
  {
    name: 'durationperquestion',
    label: 'Duration Per Question',
    type: 'text',
    required: true,
    placeholder: 'Duration Per Question',
  },
  {
    name: 'categorywiseLimit',
    label: 'Category Wise Limit',
    type: 'text',
    required: true,
    placeholder: 'Category Wise Limit',
  },
  {
    name: 'negative',
    label: 'Negative Mark',
    type: 'text',
    required: true,
    placeholder: 'Negative Mark',
  },
];
export const LIVETEST_CONFIG = [
  {
    name: 'negativeMark',
    label: 'Negative Mark',
    required: true,
    type: 'text',
    placeholder: 'Negative Mark',
  },
  {
    name: 'totalQuestions',
    label: 'Total Questions',
    type: 'text',
    required: true,
    placeholder: 'Total Questions',
  },
  {
    name: 'durationPerQuestion',
    label: 'Duration Per Question',
    type: 'text',
    required: true,
    placeholder: 'Duration Per Question',
  },
];
export const COURSEWISE_MOCK_TEST_CONFIG = [
  {
    name: 'totalquestions',
    label: 'Total Questions',
    required: true,
    type: 'text',
    placeholder: 'Total Questions',
  },
  {
    name: 'durationperquestion',
    label: 'Duration Per Question',
    type: 'text',
    required: true,
    placeholder: 'Duration Per Question',
  },
  {
    name: 'categorywiseLimit',
    label: 'Category Wise Limit',
    type: 'text',
    required: true,
    placeholder: 'Category Wise Limit',
  },
  {
    name: 'negative',
    label: 'Negative Mark',
    type: 'text',
    required: true,
    placeholder: 'Negative Mark',
  },
];

export const REWARD_CONFIG = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    placeholder: 'Title',
  },
  {
    name: 'point',
    label: 'Point',
    type: 'text',
    required: true,
    placeholder: 'Point',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    required: true,
    placeholder: 'Description',
  },
  {
    name: 'minimumRedeemablevalue',
    label: 'minimumRedeemablevalue',
    type: 'text',
    required: true,
    placeholder: 'minimumRedeemablevalue',
  },
  {
    name: 'maximumRedeemablevalue',
    label: 'maximumRedeemablevalue',
    type: 'text',
    required: true,
    placeholder: 'maximumRedeemablevalue',
  },
];
export const RAZORPAY_FIELDS = [
  {
    name: 'publicKey',
    label: 'Publi Key',
    type: 'text',
    required: true,
    placeholder: 'razorpay Public key',
  },
  {
    name: 'secretKey',
    label: 'Secrete Key',
    type: 'text',
    required: true,
    placeholder: 'razorpay Secrete key',
  },
];

export const STRIPE_FIELDS = [
  {
    name: 'publicKey',
    label: 'Publishable Key',
    type: 'text',
    required: true,
    placeholder: 'Stripe Public key',
    maxLength: 200,
  },
  {
    name: 'secretKey',
    label: 'Secret Key',
    type: 'text',
    required: true,
    placeholder: 'Stripe Secrete key',
    maxLength: 200,
  },
];

export const ADD_USER = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    required: true,
    placeholder: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'text',
    required: true,
    placeholder: 'Email',
  },
  {
    name: 'mobile',
    label: 'Mobile',
    type: 'number',
    required: true,
    placeholder: 'Mobile',
  },
  {
    name: 'role',
    label: 'Role',
    type: 'selectdrop',
    options: [],
    required: true,
    placeholder: 'Role',
  },
];

export const EDIT_USER = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    required: true,
    placeholder: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'text',
    required: true,
    placeholder: 'Email',
  },
  {
    name: 'mobile',
    label: 'Mobile',
    type: 'number',
    required: true,
    placeholder: 'Mobile',
  },
  {
    name: 'gender',
    label: 'Gender',
    type: 'selectdrop',
    required: true,
    placeholder: 'Gender',
  },
];

export const CMS_LIST_HEADING = [
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Content',
    key: 'content',
    type: 'html',
  },
];
export const FAQ_LIST_HEADING = [
  {
    label: 'Questions',
    key: 'question',
  },
];
export const USER_LIST_HEADING = [
  {
    label: 'Name',
    key: {
      image: 'thumbnailUrl',
      title: 'name',
      title2: 'email',
    },
    type: 'profilepreview',
  },
  {
    label: 'Role Name',
    key: 'role',
  },
  {
    label: 'Role Status',
    key: 'status',
  },
  {
    label: 'LAST LOGIN',
    key: 'lastLoginDate',
    type: 'dateAndTime',
  },
];

export const LOGIN_FIELDS = [
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    required: true,
    placeholder: 'email',
    size: 'big',
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    required: true,
    placeholder: 'password',
    size: 'big',
    forgotPassLink: '/ForgotPassword',
  },
  {
    name: 'captcha',
    label: 'Recaptcha',
    required: true,
    type: 'captcha',
  },
  {
    name: 'submit',
    children: 'Login',
    type: 'submit',
    btnSize: 'xl',
    btnWidth: 'full',
    label: 'Submit',
  },
];

export const FORGOT_FIELDS = [
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    required: 'required',
    placeholder: 'email',
    size: 'big',
  },
];

export const NEW_PASSWORD = [
  {
    name: 'password',
    label: 'New Password',
    type: 'password',
    required: 'required',
    placeholder: 'new password',
    size: 'big',
  },
  {
    name: 'passwordConfirm',
    label: 'Confirm Password',
    type: 'password',
    required: 'required',
    placeholder: 'confirm password',
    size: 'big',
  },
  {
    name: 'submit',
    children: 'Save',
    type: 'submit',
    btnSize: 'xl',
    btnWidth: 'full',
    label: 'Submit',
  },
];
export const NEW_USER = [
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    required: 'required',
    placeholder: 'email',
    size: 'big',
  },
  {
    name: 'password',
    label: 'New Password',
    type: 'password',
    required: 'required',
    placeholder: 'new password',
    size: 'big',
  },
  {
    name: 'passwordConfirm',
    label: 'Confirm Password',
    type: 'password',
    required: 'required',
    placeholder: 'confirm password',
    size: 'big',
  },
  {
    name: 'submit',
    children: 'Save',
    type: 'submit',
    btnSize: 'xl',
    btnWidth: 'full',
    label: 'Submit',
  },
];
export const ADD_USER_ROLE = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    required: true,
    placeholder: 'Name',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    required: true,
    placeholder: 'Description',
  },
];

export const USER_ROLE_HEADING = [
  {
    label: 'Role Name',
    key: 'name',
  },
  {
    label: 'Description',
    key: 'description',
  },
];

export const ACCESS_HEADING = [
  '',
  'Full Access',
  'View',
  'Create',
  'Edit',
  'Delete',
  // "Assign Owner"
];

export const ACCESS_DATA = [
  {
    resource: 'course',
    methods: 'ALL,GET,POST,DELETE,PUT',
    action: 'allow',
  },
  {
    resource: 'acl',
    methods: 'ALL,GET,POST,DELETE,PUT',
    action: 'allow',
  },
  {
    resource: 'previousyearexam',
    methods: 'ALL,GET,POST,DELETE,PUT',
    action: 'allow',
  },
  {
    resource: 'liveexam',
    methods: 'ALL,GET,POST,DELETE,PUT',
    action: 'allow',
  },
  {
    resource: 'configuration',
    methods: 'ALL,GET,POST,DELETE,PUT',
    action: 'allow',
  },
];

function Percentage({ value }) {
  return <span>{value}%</span>;
}
Percentage.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
export const PLAN_HEADING = [
  { label: 'Plan Name', key: 'name' },
  { label: 'Expires On', key: 'expiredOn', type: 'date' },
  {
    label: 'Price',
    key: 'price',
    type: 'custom',
    component: Currency,
    sortable: true,
  },
  {
    label: 'Discount',
    key: 'discount',
    type: 'custom',
    component: Percentage,
    sortable: true,
  },
  {
    label: 'final Amount',
    key: 'finalAmount',
    type: 'custom',
    component: Currency,
    sortable: true,
  },
];
export const CHAPTER_ADD = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    placeholder: 'Title',
  },
  {
    name: 'subTitle',
    label: 'Subtitle',
    type: 'text',
    required: true,
    placeholder: 'Subtitle',
    rows: 3,
  },
];

export const CHAPTER_MATERIAL_ADD = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: 'required',
    placeholder: 'Title',
  },
  {
    name: 'subTitle',
    label: 'Sub Title',
    type: 'text',
    required: 'required',
    placeholder: 'Sub Title',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    required: 'required',
    placeholder: 'Description',
    rows: 5,
  },
  {
    name: 'isFeatured',
    label: 'Featured',
    type: 'checkbox',
  },
  {
    name: 'isPaid',
    label: 'Free',
    type: 'checkbox',
  },
  {
    name: 'materialId',
    label: 'Upload material',
    type: 'file',
    required: 'required',
  },
];

export const CHAPTER_MATERIAL_ADD2 = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: 'required',
    placeholder: 'Title',
  },
  {
    name: 'subTitle',
    label: 'Sub title',
    type: 'text',
    required: 'required',
    placeholder: 'Sub title',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    required: 'required',
    placeholder: 'Description',
    rows: 5,
  },
  {
    name: 'isFeatured',
    label: 'Featured',
    type: 'checkbox',
  },
  {
    name: 'isPaid',
    label: 'Free',
    type: 'checkbox',
  },
];

export const SEND_MAIL = [
  {
    name: 'replyTo',
    // required: false,
    label: 'Reply To',
  },
  {
    name: 'from',
    required: 'required',
    label: 'From',
  },
  {
    name: 'content',
    required: 'required',
    label: 'Content',
  },
  {
    name: 'subject',
    required: 'required',
    label: 'Subject',
  },
];
export const SAMPLE_QUESTION = [
  ['question', 'option1', 'option2', 'option3', 'option4', 'isAnswer', 'explanation'],
  [
    'What is the square root of 9?',
    5,
    3,
    6,
    1,
    'option2',
    'Donec a purus ut metus varius pharetra. Maecenas at rutrum libero, ac sagittis urna. Sed interdum facilisis risus, id ornare tortor finibus in. Maecenas dignissim et velit non accumsan. Cras a suscipit risus, tincidunt finibus felis. Nullam at mauris sem. Donec nibh diam, blandit et facilisis in, ultrices sed sem.',
  ],
];
export const SAMPLE_STUDENT = [
  ['name', 'email', 'mobile'],
  ['Ram', 'ram@gmail.com', '9874561230'],
];
export const SEND_NOTIFICATION = [
  {
    name: 'title',
    required: 'required',
    label: 'Title',
  },
  {
    name: 'content',
    required: 'required',
    label: 'Content',
  },
];
