import React from 'react';
import PropTypes from 'prop-types';

import DropDown from 'Components/SelectDrop';
import useLocalForge from 'storage/useLocalForge';

function DropDownOptions({ url, extraOptions, courseId, ...props }) {
  const [data] = useLocalForge(url, extraOptions);
  if (url === 'subject') {
    const cst = data.filter((dt) => dt.courseId === `${courseId}`);
    return <DropDown options={cst || []} {...props} />;
  }
  return <DropDown options={data || []} {...props} />;
}
DropDownOptions.propTypes = {
  url: PropTypes.string,
  courseId: PropTypes.string.isRequired,
  extraOptions: PropTypes.arrayOf(),
};

DropDownOptions.defaultProps = {
  url: '',
  extraOptions: [],
};

export default DropDownOptions;
