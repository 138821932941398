import React from 'react';
import PropTypes from 'prop-types';

import { ClassNames } from 'helper';
import Styles from './more.module.scss';

export default function MoreOptions({ options, position, toggle, onClick, custom }) {
  return (
    <div className={ClassNames([Styles.more_option_area, Styles[position], [custom]])}>
      <button type="button" aria-label="More" className={Styles.more_btn} onClick={onClick} />
      {toggle && (
        <ul>
          {options?.map((item) => (
            <li key={item.key}>
              <button type="button" aria-label="button" onClick={item.action}>
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

MoreOptions.propTypes = {
  options: PropTypes.arrayOf().isRequired,
  position: PropTypes.string,
  custom: PropTypes.string,
  toggle: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

MoreOptions.defaultProps = {
  position: 'relative',
  custom: '',
};
