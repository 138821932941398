import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// import { Container } from './styles';
import Styles from './logo.module.scss';

function Logo({ logoIcon, logoFull, logoIconLight, logoFullLight, theme, visibleLogo, position }) {
  const classes = [Styles.logoItem, Styles[position]].join(' ');

  if (visibleLogo && visibleLogo === 'icon') {
    if (theme && theme === 'light') {
      return (
        <div>
          <Link to="/dashboard" className={classes}>
            <img className={[Styles.logoIcon]} src={logoIconLight} alt="" />
          </Link>
        </div>
      );
    }
    return (
      <div>
        <Link to="/dashboard" className={classes}>
          <img className={[Styles.logoIcon]} src={logoIcon} alt="" />
        </Link>
      </div>
    );
  }
  if (theme && theme === 'light') {
    return (
      <div>
        <Link to="/dashboard" className={classes}>
          <img className={[Styles.logoFull]} src={logoFullLight} alt="" />
        </Link>
      </div>
    );
  }
  return (
    <div>
      <Link to="/dashboard" className={classes}>
        <img className={[Styles.logoFull]} src={logoFull} alt="" />
      </Link>
    </div>
  );
}

export default Logo;
Logo.propTypes = {
  logoIcon: PropTypes.string,
  logoFull: PropTypes.string,
  logoIconLight: PropTypes.string,
  logoFullLight: PropTypes.string,
  theme: PropTypes.string,
  visibleLogo: PropTypes.string,
  position: PropTypes.string,
};

Logo.defaultProps = {
  logoIcon: '',
  logoFull: '',
  logoIconLight: '',
  logoFullLight: '',
  theme: '',
  visibleLogo: '',
  position: '',
};
