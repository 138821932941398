import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import { Tooltip } from '@acodez/tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ClassNames, isEmpty } from 'helper';
import InfoIcon from 'images/icon-info.svg';
import Styles from './datepick.module.scss';

export default function DatePick({
  size,
  color,
  label,
  requiredText,
  disabled,
  value,
  onChange,
  name,
  info,
  minDate,
  maxDate,
}) {
  // eslint-disable-next-line no-unused-vars
  const [data, setdata] = useState(new Date());

  const handleDateChange = (date) => {
    setdata(date);
    onChange(name, date, 'date');
  };

  return (
    <div className={Styles.field_area}>
      {label && (
        <label htmlFor={name} className={[Styles.label_text]}>
          {label}
          {requiredText && <span>{requiredText}</span>}
          {!isEmpty(info) && (
            <Tooltip text={info} position="right">
              <img className={Styles.tooltipIcon} src={InfoIcon} alt="" />
            </Tooltip>
          )}
        </label>
      )}
      <div className={Styles.field_box}>
        <DatePicker
          onChange={handleDateChange}
          value={value && typeof value === 'string' ? new Date(value) : value}
          name={name}
          className={ClassNames([Styles.field_box, Styles[size], Styles[color]])}
          format="dd/MM/yyyy"
          calendarIcon=""
          clearIcon=""
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
}
DatePick.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  requiredText: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
  minDate: PropTypes.shape,
  maxDate: PropTypes.shape,
};
DatePick.defaultProps = {
  color: 'primary',
  size: 'md',
  label: '',
  requiredText: false,
  disabled: false,
  info: '',
  minDate: moment().toDate(),
  maxDate: new Date(2080, 6, 24),
};
