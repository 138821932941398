/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import upload from 'images/icon-upload.svg';
import Styles from './custom.module.scss';

const Uppy = require('@uppy/core');
const Tus = require('@uppy/tus');
const { useUppy, Dashboard } = require('@uppy/react');

function CustomFileUploader({ label, placeholder, errorMessage }) {
  const uppy = useUppy(() =>
    new Uppy({ autoProceed: false }).use(Tus, {
      endpoint: 'https://tusd.tusdemo.net/files/',
    })
  );
  // uppy.use(ProgressBar, {
  //   target: '.UploadForm',
  //   fixed: false,
  //   hideAfterFinish: true,
  // });

  return (
    <div className={Styles.file_field}>
      {label && (
        <label htmlFor="Dashboard" className={[Styles.fileLabel]}>
          {label}
        </label>
      )}
      <div className={Styles.import_upload}>
        <div className={Styles.custom_upload}>
          <img src={upload} alt="upload" />
          {/* <h4>Drag and drop or <span>choose a file</span> to upload your data</h4> */}
          <p>{placeholder}</p>
        </div>
        {/* <div className={Styles.upload_area}>
          <h4>
            Drag and drop or <span>choose a file</span> to upload your data
          </h4>
        </div> */}
        {/* <Dashboard
          id="Dashboard"
          width="100%"
          height="280px"
          hideUploadButton
          hideRetryButton
          hidePauseResumeButton
          hideCancelButton
          hideProgressAfterFinish
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          showRemoveButtonAfterComplete
          uppy={uppy}
          // locale={{
          //   strings: {
          //     // dropPaste: '%{browse}',
          //     // browse: 'Drop video, audio, PDF, or other file(s) here or click to choose files',
          //   },
          // }}
        /> */}
      </div>
      {errorMessage && <span className={[Styles.error_text]}>{errorMessage}</span>}
    </div>
  );
}

CustomFileUploader.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

CustomFileUploader.defaultProps = {
  label: '',
  placeholder: '',
  errorMessage: '',
};

export default CustomFileUploader;
