import axios from 'axios';
import localForage from 'localforage';

import { isEmpty } from 'helper';

/**
 * setItem
 * @param {*} key
 * @param {*} data
 * @param {*} callback
 */
function setItem(key, data, callback = () => {}) {
  localForage.setItem(key, data, callback);
}

/**
 * getItem
 * @param {*} key
 * @param {*} callback
 * @returns
 */
async function getItem(key, callback) {
  const value = await localForage.getItem(key, callback);
  return value;
}

/**
 * setCourseAndSubject
 * @param {} response
 */
function setCourseAndSubject(response) {
  setItem(
    'course',
    response?.data?.data.map((dt) => ({ value: dt._id, label: dt.title, key: dt._id }))
  );
  const sub = [];
  const chap = [];
  response?.data?.data.forEach((dt) => {
    if (!isEmpty(dt?.subject)) {
      const v = dt?.subject
        .filter((val) => val.isDeleted === false)
        .map((subject) => ({
          value: subject._id,
          label: subject.title,
          courseId: dt._id,
        }));
      sub.push(...v);
      dt?.subject.forEach((subject) => {
        if (!isEmpty(subject.chapter)) {
          const v = subject.chapter
            .filter((val) => val.isDeleted === false)
            .map((chapter) => ({
              value: chapter._id,
              label: chapter.title,
              courseId: dt._id,
              subjectId: subject._id,
            }));
          chap.push(...v);
        }
      });
    }
  });
  setItem('subject', sub);
  setItem('chapter', chap);
}

/**
 * fetchAndStoreOptions
 * @param {*} url
 * @param {*} fields
 */
async function fetchAndStoreOptions(url, fields, name = '') {
  if (url) {
    const response = await axios.get(`/${url}?fields=${fields}`, {
      headers: {
        Authorization: localStorage.jwtToken,
      },
    });
    if (url === 'course') {
      setCourseAndSubject(response);
    } else if (name === 'permissions') {
      setItem(name, response?.data?.data);
    } else {
      setItem(
        name,
        response?.data?.data?.value?.map((val) => ({ label: val, key: val }))
      );
    }
  }
}

/**
 * getAndStoreAllItems
 */
function getAndStoreAllItems() {
  fetchAndStoreOptions(
    'course',
    'title,subject._id,subject.title,subject.isDeleted,subject.chapter._id,subject.chapter.title,subject.chapter.isDeleted'
  );
  fetchAndStoreOptions('configuration/courseLanguages', '', 'languages');
  fetchAndStoreOptions('configuration/userpermissions', '', 'permissions');
}

const storage = { getAndStoreAllItems, setItem, getItem, fetchAndStoreOptions };

export default storage;
