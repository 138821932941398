import React from 'react';
import PropTypes from 'prop-types';

import Styles from './navbar.module.scss';

function NavBar({ direction, theme, children, size, type }) {
  return (
    <div
      className={[Styles.NavBar, Styles[theme], Styles[direction], Styles[size], Styles[type]].join(
        ' '
      )}
    >
      {children && children}
    </div>
  );
}

export default NavBar;
NavBar.propTypes = {
  theme: PropTypes.string,
  direction: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.element,
};

NavBar.defaultProps = {
  theme: 'dark',
  direction: 'vertical',
  size: 'normal',
  type: 'main',
  children: null,
};
