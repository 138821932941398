import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';

import CustomFileUploader from 'Components/CustomFileUploader';
import DatePicker from 'Components/DatePick';
import DropDownOptions from 'Components/DropDownOptions';
import ImageUploader from 'Components/ImageUploader';
import RecaptchaModule from 'Components/Recaptcha';
// import CheckBox from "../CheckBox";
import SelectDrop from 'Components/SelectDrop';
import SimpleSelect from 'Components/SimpleSelect';
import Switch from 'Components/Switch';
import TimePicker from 'Components/TimePicker';
import Button from '../Button';
import CheckBox from '../CheckBox';
import InputField from '../InputField';
import SearchField from '../SearchField';
import Select from '../Select';
import TextArea from '../Textarea';
import Styles from './formbuilder.module.scss';

import '@acodez/tooltip/dist/index.css';
import 'suneditor/dist/css/suneditor.min.css';

// import { Container } from './styles';

function FormBuilder({ fields, formdata, errors, onChange, placeholder, onImageChange }) {
  const onChangecontent = (name, value) => {
    onChange(name, value);
  };
  useEffect(
    () => () => {
      // eslint-disable-next-line no-param-reassign
      formdata = {};
    },
    []
  );
  return (
    <form>
      {fields.map(({ type, name, label, ...other }) => {
        switch (type) {
          case 'file':
            return (
              <CustomFileUploader
                value={formdata?.[name]}
                key={name}
                name={name}
                errorMessage={errors?.[name]}
                onChange={onChange}
                label={label}
                placeholder={placeholder}
                {...other}
              />
            );
          case 'switch':
            return (
              <Switch
                name={name}
                isChecked={formdata?.[name]}
                onChange={onChange}
                label={label}
                placeholder={placeholder}
                {...other}
              />
            );
          case 'image':
            return (
              <ImageUploader
                name={name}
                onChange={onImageChange}
                label={label}
                placeholder={placeholder}
                errorMessage={errors?.[name]}
                {...other}
              />
            );

          case 'select':
            return (
              <SimpleSelect
                key={name}
                onChange={onChange}
                errorMessage={errors?.[name]}
                value={formdata?.[name]}
                defaultValue={formdata?.[name]}
                name={name}
                placeHolder={placeholder}
                label={label}
                labelText="choose an option"
                {...other}
              />
            );
          case 'dropdown':
            return (
              <Select
                key={name}
                onChange={onChange}
                value={formdata?.[name]}
                name={name}
                label={label}
                labelText="choose an option"
                {...other}
              />
            );
          case 'date':
            return (
              <DatePicker
                value={formdata?.[name]}
                style={{ width: 280 }}
                key={name}
                onChange={onChange}
                name={name}
                label={label}
                {...other}
              />
            );
          case 'search':
            return (
              <SearchField
                onChange={onChange}
                key={name}
                value={formdata?.[name]}
                name={name}
                {...other}
              />
            );
          case 'submit':
            return (
              <Button type={type} key={name} name={name} {...other}>
                {label}
              </Button>
            );
          case 'checkbox':
            return (
              <CheckBox
                key={name}
                s={name}
                label={label}
                name={name}
                onChange={onChange}
                {...other}
              />
            );
          case 'textarea':
            return (
              <TextArea
                value={formdata?.[name]}
                onChange={onChange}
                key={name}
                name={name}
                label={label}
                {...other}
                errorMessage={errors?.[name]}
              />
            );
          case 'editor':
            return (
              <>
                {label && <label>{label}</label>}
                <SunEditor
                  name={name}
                  placeholder="Question"
                  onChange={(value) => onChangecontent(name, value)}
                  setContents={formdata?.[name]}
                  setOptions={{
                    height: 'auto',
                    minHeight: 400,
                    buttonList: [
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                        'image',
                        'align',
                      ],
                    ],
                  }}
                />
                {errors[name] && <span className={[Styles.error_text]}>{errors[name]}</span>}
              </>
            );
          case 'captcha':
            return <RecaptchaModule errors={errors} onChange={onChange} {...other} />;
          case 'dropdownOptions':
            return (
              <DropDownOptions
                name={name}
                {...other}
                placeholder="select one"
                label={label}
                noAdd
                onChange={(name, val) => onChange(name, val, 'dropdownOptions')}
                value={formdata[name]}
              />
            );
          case 'selectdrop':
            return (
              <SelectDrop
                onChange={onChange}
                key={name}
                value={formdata?.[name]}
                label={label}
                name={name}
                errorMessage={errors?.[name]}
                {...other}
              />
            );
          case 'time':
            return (
              <TimePicker
                onChange={onChange}
                value={formdata?.[name]}
                label={label}
                key={name}
                name={name}
                type={type}
                errorMessage={errors?.[name]}
                {...other}
              />
            );
          case 'button':
            return (
              <Button onChange={onChange} key={name} value={formdata?.[name]} {...other}>
                {name}
              </Button>
            );
          default:
            return (
              <InputField
                onChange={onChange}
                value={formdata?.[name]}
                label={label}
                key={name}
                name={name}
                type={type}
                errorMessage={errors?.[name]}
                {...other}
              />
            );
        }
      })}
    </form>
  );
}

FormBuilder.propTypes = {
  fields: PropTypes.arrayOf().isRequired,
  formdata: PropTypes.shape,
  errors: PropTypes.shape,
  onImageChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

FormBuilder.defaultProps = {
  formdata: {},
  errors: {},
  placeholder: '',
  onImageChange: () => {},
};
export default FormBuilder;
