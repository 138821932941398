/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.parcel';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PDFViewer } from 'react-view-pdf';
// import Graph from "Components/Graph";
import Areachart from '@acodez/areachart';
// import PDFViewer from 'pdf-viewer-reactjs';
import PropTypes from 'prop-types';

import actions from 'actions';
import CheckBox from 'Components/CheckBox';
import MoreOptions from 'Components/MoreOptions';
import Pdf from 'Components/PdfViewer';
import { formatDate, getActions, imagePreview, isEmpty } from 'helper';
import Modal from '../Modals';
import Reviews from '../Reviews';
import Styles from './cards.module.scss';

function Cards({ items, itemPerRow, gap, margin, getData, review }) {
  return (
    <div
      data-gap={gap}
      data-row={itemPerRow}
      className={[Styles.cardsWrapper, Styles[margin]].join(' ')}
    >
      <div className="cardContentInner">
        {items.map((item) => (
          <CardItem
            key={item.id}
            title={item.title}
            id={item.id}
            checked={item.checked}
            imageSrc={item.imageSrc}
            desc={item.desc}
            boxStyle={item.boxStyle}
            itemStyle={item.itemStyle}
            flexStyle={item.flexStyle}
            date={item.date}
            excerpt={item.excerpt}
            size={item.size}
            length={item.length}
            status={item.status}
            path={item.path}
            enableModal={item.enableModal}
            graph={item.graph}
            color={item.color}
            xaxisdata={item.xaxisdata}
            yaxisdata={item.yaxisdata}
            options={item.options}
            checkbox={item.checkbox}
            checkboxName={item.checkboxName}
            checkFunction={item.checkFunction}
            featured={item.featured}
            free={item?.free}
            getData={getData}
            review={review}
            materialType={item.materialType}
            description={item.description}
            source={item?.source}
            getsignaturePath={item?.getsignaturePath}
          />
        ))}
      </div>
    </div>
  );
}

function CardItem({
  title,
  id,
  itemStyle,
  checked,
  boxStyle,
  flexStyle,
  desc,
  date,
  excerpt,
  imageSrc,
  size,
  status,
  enableModal,
  path,
  graph,
  color,
  xaxisdata,
  yaxisdata,
  options,
  checkbox,
  checkboxName,
  checkFunction,
  featured,
  free,
  getData,
  materialType,
  description,
  length,
  source,
  getsignaturePath,
}) {
  const [data, setdata] = useState([]);
  const [toggle, settoggle] = useState(false);
  const [numPages, setNumPages] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    if (enableModal === 'yes') {
      const action = getActions('COURSE_MATERIAL_REVIEW');
      getData(`coursematerial/${id}/reviews`, action.GETALL).then(({ payload }) => {
        if (payload?.data?.statusCode === 200) {
          setdata(payload?.data?.data);
        }
      });
    }
  }, []);

  function videoPlayPause(e) {
    const v = document.getElementsByClassName('videoPlayer')[0];
    if (v.paused) {
      v.play();
      v.controls = 'controls';
    } else {
      v.pause();
      v.controls = 'controls';
    }
    e.preventDefault();
  }

  const handleOnClick = () => {
    settoggle(!toggle);
  };

  const handleToggle = () => {
    if (toggle) {
      settoggle(false);
    }
  };
  return (
    <div className={[Styles.card]} onClick={handleToggle} aria-hidden>
      <div className={[Styles.cardInner, Styles[boxStyle], Styles[flexStyle]].join(' ')}>
        {imageSrc && (
          <div className={Styles.cardMedia}>
            {enableModal && enableModal === 'yes' ? (
              <Modal
                showCloseBtn
                actionBtnText="View"
                actionBtnIcon={imageSrc}
                actionBtnStyle="icon"
                saveBtnText="Save"
                size="xl"
                modalHeading={title}
                type="material"
                path={source}
                getsignaturePath={getsignaturePath}
              >
                {materialType === 'Video' && path ? (
                  <div className="materialMedia">
                    {/* <div className="playBtn" >Play</div> */}
                    <video
                      className="videoPlayer"
                      onClick={videoPlayPause}
                      width="100%"
                      height="100%"
                      autoPlay
                      controls
                      // poster={imageSrc}
                    >
                      <source src={path} type="video/mp4" />
                    </video>
                  </div>
                ) : materialType === 'Audio' && path ? (
                  <div className="materialMedia">
                    {/* <div className="playBtn" >Play</div> */}
                    <audio
                      className="audioPlayer"
                      // onClick={videoPlayPause}
                      // width="100%"
                      // height="100%"
                      // poster={imageSrc}
                      controls
                      autoPlay
                    >
                      <source src={path} type="audio/mpeg" />
                    </audio>
                  </div>
                ) : (
                  <div className="materialMedia">
                    {/* <div className="playBtn" >Play</div> */}
                    {/* <iframe
                      src={path}
                      title="title"
                      // frameBorder="0"
                      // scrolling="auto"
                      // height="100%"
                      // width="100%"
                    /> */}
                    {/* <PDFViewer
                      document={{
                        url: path,
                      }}
                    /> */}
                    {/* <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={pageNumber} />
                    </Document>
                    <p>
                      Page {pageNumber} of {numPages}
                    </p> */}
                    {/* <PDFViewer url={path} /> */}
                    {/* <PDFViewer url="https://www.clickdimensions.com/links/TestPDFfile.pdf" /> */}
                    {console.log('data')}
                    {console.log(path)}
                    {console.log('data')}
                    {/* <Pdf path={path} /> */}
                  </div>
                )}
                <div className="materialInfo">
                  <h4>{title}</h4>
                  <div className="materialDesc">{desc}</div>
                  <div className="materialDate">{date}</div>
                  <div className="materialExcerpt">
                    <h6>{description}</h6>
                    <p>{excerpt}</p>
                  </div>
                  <div className="ModalReviewsWrapper">
                    <Reviews
                      size="small"
                      items={data?.map((val) => {
                        const data = {};
                        data.id = val._id;
                        data.reviewDesc = val.review;
                        data.reviewDate = formatDate(val.createdAt);
                        data.reviewRating = val.rating;
                        data.profileImage = imagePreview(
                          val?.student?.mediaStorageType,
                          val?.student?.profileImageName,
                          val?.student?.profileImagePath
                        );
                        data.profileName = val?.student?.name;
                        return data;
                      })}
                    />
                  </div>
                </div>
              </Modal>
            ) : (
              <div className={Styles.flex_img}>
                <img src={imageSrc} alt="pic" />
              </div>
            )}
            {size && (
              <div className={Styles.cardSize}>
                <span>{size}</span>
              </div>
            )}
            {length && (
              <div className={Styles.cardLength}>
                <span>{length}</span>
              </div>
            )}
            {status && (
              <div className={Styles.cardStatus}>
                <span>{status}</span>
              </div>
            )}
          </div>
        )}
        {checkbox ? (
          <div className={Styles.check}>
            <CheckBox
              name={checkboxName}
              label={title}
              position="absolute"
              checked={checked && id === checked}
              onChange={(e) => checkFunction(id, e)}
            />{' '}
          </div>
        ) : (
          ''
        )}
        {!isEmpty(options) ? (
          <>
            <MoreOptions
              options={options}
              position="absolute"
              toggle={toggle}
              onClick={handleOnClick}
            />
            {toggle && (
              <button
                type="button"
                aria-label="outerdiv"
                className={Styles.outerdiv}
                onClick={() => settoggle(false)}
              />
            )}
          </>
        ) : (
          ''
        )}
        {featured && <span className={Styles.featured}>Featured</span>}
        {free && <span className={Styles.free}>Free</span>}
        <div className={[Styles.cardContent, Styles[itemStyle]].join(' ')}>
          {title && <h4 className={Styles.cardTitle}>{title}</h4>}
          {desc && <div className={Styles.cardDesc}>{desc}</div>}
          {date && <div className={Styles.cardDate}>{date}</div>}
        </div>
        {graph ? (
          <div className={Styles.chart_area}>
            <Areachart
              xaxisdata={xaxisdata}
              yaxisdata={yaxisdata}
              smoothline
              height="90px"
              xyaxisdatahidden
              color={color}
              showgrid={false}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
Cards.propTypes = {
  items: PropTypes.instanceOf(Array),
  itemPerRow: PropTypes.string,
  gap: PropTypes.string,
  margin: PropTypes.string,
  getData: PropTypes.func.isRequired,
  review: PropTypes.string,
};
Cards.defaultProps = {
  items: [],
  itemPerRow: '',
  gap: '',
  margin: '',
  review: '',
};
CardItem.propTypes = {
  title: PropTypes.string,
  itemStyle: PropTypes.string,
  boxStyle: PropTypes.string,
  flexStyle: PropTypes.string,
  desc: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  imageSrc: PropTypes.string,
  size: PropTypes.string,
  length: PropTypes.string,
  status: PropTypes.string,
  enableModal: PropTypes.string,
  graph: PropTypes.string,
  color: PropTypes.string,
  xaxisdata: PropTypes.string,
  yaxisdata: PropTypes.string,
  options: PropTypes.arrayOf(),
  id: PropTypes.string,
  checked: PropTypes.bool,
  path: PropTypes.string,
  checkbox: PropTypes.bool,
  checkboxName: PropTypes.string,
  checkFunction: PropTypes.func,
  featured: PropTypes.bool,
  free: PropTypes.bool,
  getData: PropTypes.func,
  materialType: PropTypes.string,
  description: PropTypes.string,
  source: PropTypes.string,
  getsignaturePath: PropTypes.func,
};
CardItem.defaultProps = {
  title: '',
  itemStyle: '',
  boxStyle: '',
  flexStyle: '',
  desc: '',
  date: '',
  excerpt: '',
  imageSrc: '',
  size: '',
  length: '',
  status: '',
  enableModal: '',
  graph: '',
  color: '',
  xaxisdata: '',
  yaxisdata: '',
  options: [],
  id: '',
  checked: false,
  checkbox: false,
  path: '',
  checkboxName: '',
  materialType: '',
  description: '',
  checkFunction: () => {},
  getsignaturePath: () => {},
  getData: () => {},
  featured: false,
  free: false,
  source: '',
};

const mapStateToProps = (state) => ({
  review: state.coureMaterialReview,
});
export default withRouter(connect(mapStateToProps, actions)(Cards));
