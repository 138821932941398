import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import InputField from 'Components/InputField';
import Popup from 'Components/Popup';
import useOutsideClick from 'Components/Useoutsideclick';
import { ClassNames, isEmpty } from 'helper';
import Styles from './selectdrop.module.scss';

export default function SelectDrop({
  value,
  options = [],
  label,
  name,
  errorMessage,
  noAdd = true,
  onChange,
  extraButton,
  disabled = false,
  required,
  onNewOption,
  placeholder,
  width,
  className,
  margin,
}) {
  const [toggle, settoggle] = useState(false);
  const [modalopen, setmodalopen] = useState(false);
  const [extra, setextra] = useState('');
  const [error, seterror] = useState('');
  const handleclick = () => {
    settoggle(!toggle);
  };

  const handleopenclick = () => {
    setmodalopen(true);
  };

  const handlemodalclose = () => {
    setmodalopen(false);
  };

  const handleChange = (val) => {
    onChange(name, val);
  };

  const ref = useRef();

  useOutsideClick(ref, () => {
    settoggle(false);
  });

  const handlesavelanguage = () => {
    if (extra !== '') {
      onNewOption(extra);
      setextra('');
      setmodalopen(false);
      seterror('');
    } else {
      seterror("Language can't be empty");
    }
  };

  return (
    <div className={ClassNames([Styles.selectdrowrap, Styles[margin]])}>
      {label && <label className={Styles.label}>{label}</label>}
      <div
        ref={ref}
        className={ClassNames([Styles.select_area, Styles[className]])}
        style={{ width }}
      >
        <button
          type="button"
          className={ClassNames([
            Styles.tab_select,
            errorMessage && Styles.error,
            disabled && Styles.disable,
          ])}
          onClick={handleclick}
        >
          <span className={ClassNames([value ? Styles.val : Styles.placeholder])}>
            {(!isEmpty(value) &&
              // eslint-disable-next-line dot-notation
              options.filter((val) => (val.key || val.value) === value)[0]?.['label']) ||
              (required ? `${placeholder}*` : placeholder)}
          </span>
          <div className={Styles[toggle ? 'rotate' : '']} />
        </button>
        {errorMessage && <span className={[Styles.error_text]}>{errorMessage}</span>}

        <ul className={Styles[toggle ? 'list' : '']}>
          {options?.map((val) => (
            <li key={name} className={Styles[value === val?.key ? 'active' : '']}>
              <button
                type="button"
                onClick={() => {
                  settoggle(false);
                  handleChange(val.key || val.value);
                }}
              >
                {val.label}
              </button>
            </li>
          ))}

          {!options && <li>No Content Here</li>}
          {noAdd && (
            <li aria-hidden className={Styles.listbtn} onClick={handleopenclick}>
              {extraButton?.icon && (
                <img className={Styles.icon} src={extraButton?.icon} alt="icon" />
              )}
              {extraButton?.label}
            </li>
          )}
        </ul>
      </div>
      {name === 'courseLanguage' && (
        <Popup
          modalHeading="Add Language"
          saveBtnText="Add New"
          isModalOpen={modalopen}
          showCloseBtn
          saveBtnFunction={handlesavelanguage}
          cancelBtnFunction={handlemodalclose}
          closemodal={handlemodalclose}
        >
          <InputField onChange={(name, value) => setextra(value)} value={extra} name="extra" />
          {error && <span className={[Styles.error_text]}>{error}</span>}
        </Popup>
      )}
    </div>
  );
}

SelectDrop.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onNewOption: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  width: PropTypes.string,
  className: PropTypes.string,
  margin: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  extraButton: PropTypes.shape,
  disabled: PropTypes.bool,
  noAdd: PropTypes.bool,
};

SelectDrop.defaultProps = {
  margin: '',
  label: '',
  name: '',
  errorMessage: '',
  extraButton: {},
  disabled: false,
  noAdd: true,
  onNewOption: () => {},
  placeholder: '',
  required: false,
  width: '',
  className: '',
};
