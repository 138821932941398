import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'actions';
import Styles from './searchFields.module.scss';
// import { Container } from './styles';

function SearchField({
  radius,
  border,
  size,
  searchIcon,
  theme,
  width,
  mode,
  apiData,
  getData,
  loading,
  setSearchData,
  ...rest
}) {
  /**
   * handleSearch
   * @param {*} e
   */
  const handleSearch = (e) => {
    getData(apiData.url, apiData.action.GETALL, {
      fields: apiData.fields,
      fileType: apiData.type,
      ...apiData.query,
      search: e.target.value,
    }).then((data) => {
      setSearchData(data?.payload?.data?.data);
    });
  };
  return (
    <div className={[Styles.searcFieldWrap, Styles[theme], Styles[width], Styles[mode]].join(' ')}>
      {searchIcon && (
        <span className={[Styles.searchIcon]}>
          <img src={searchIcon} alt="search" />
          <input type="submit" value="search" />
        </span>
      )}
      {/* <input
        className={[Styles.SearchField, Styles[radius], Styles[border], Styles[size]].join(' ')}
        type="search"
        onChange={handleSearch}
        {...rest}
      /> */}
      <DebounceInput
        className={[Styles.SearchField, Styles[radius], Styles[border], Styles[size]].join(' ')}
        type="search"
        onChange={handleSearch}
        debounceTimeout={600}
        {...rest}
      />
      {loading && <div className={Styles.loading} />}
    </div>
  );
}
SearchField.propTypes = {
  radius: PropTypes.string,
  border: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string,
  width: PropTypes.string,
  mode: PropTypes.string,
  searchIcon: PropTypes.string,
  apiData: PropTypes.string.isRequired,
  getData: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  setSearchData: PropTypes.func,
};

SearchField.defaultProps = {
  radius: 'round',
  border: 'none',
  size: 'normal',
  theme: 'dark',
  width: 'auto',
  mode: 'large',
  loading: false,
  searchIcon: '',
  setSearchData: () => {},
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, actions)(SearchField);
