/* eslint-disable no-empty-pattern */
import axios from 'axios';
import { applyMiddleware, compose, createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import thunk from 'redux-thunk';

import rootReducer from 'reducers';

axios.defaults.baseURL = '/api/v1.0/';
// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || '/api/v1.0/';

const client = axios.create({
  // all axios can be used, shown in axios documentation
  responseType: 'json',
});

const initialState = {};

const middlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success({}, req) {
          // contains information about request object
          // ...
          req.headers.Authorization = localStorage.getItem('jwtToken');
          return req;
        },
        error({}, error) {
          // ...
          return error;
        },
      },
    ],
    response: [
      {
        success({}, req) {
          // console.log(req); //contains information about request object
          // ...
          return req;
        },
        error({}, error) {
          // ...
          const errorStatus = error.response.status;
          if (errorStatus) {
            if (errorStatus === 401) {
              localStorage.removeItem('jwtToken');
              window.location.href = '/login';
            }
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, axiosMiddleware(client, middlewareConfig)))
);

export default store;
