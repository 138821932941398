/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import { Tooltip } from '@acodez/tooltip';
import PropTypes from 'prop-types';

import { isEmpty } from 'helper';
import InfoIcon from 'images/icon-info.svg';
import showHideIconDefault from './images/showHidePass.svg';
import Styles from './inputFields.module.scss';

function InputField({
  type,
  label,
  onChange,
  required,
  defaultValue,
  requiredText,
  errorMessage,
  radius,
  border,
  size,
  maxLength,
  margin,
  max,
  min,
  forgotPassLink,
  showHideIcon,
  value,
  info,
  isFocus,
  NumberMax,
  ...rest
}) {
  const ref = useRef();

  useEffect(() => {
    if (isFocus) {
      ref?.current?.focus();
    }
  }, []);
  const [ipType, setIpType] = useState('password');
  const ontextchange = (e) => {
    if (e.target.type === 'number') {
      if (e.target.name === 'discount') {
        const val = `${e.target.value}`;
        onChange(e.target.name, e.target.value > 100 ? val.slice(0, 2) : val, e.target.type);
      } else {
        const val = `${e.target.value}`.slice(0, NumberMax);
        onChange(e.target.name, val, e.target.type);
      }
    } else {
      onChange(e.target.name, e.target.value, e.target.type);
    }
  };
  const onphonechange = (e) => {
    let val = `${e.target.value}`.slice(0, 10);
    val = val.replace(/[^0-9]*/g, '');
    onChange(e.target.name, val, e.target.type);
  };

  const onTimeChange = (e) => {
    onChange(e.target.name, e.target.value);
  };
  return (
    <div className={[Styles.inputWrap, Styles[margin], Styles[size]].join(' ')}>
      {label && (
        <>
          <label className={[Styles.label_text]}>
            {label}
            {requiredText && <span>{requiredText}</span>}
            {!isEmpty(info) && (
              <Tooltip text={info} position="right">
                <img className={Styles.tooltipIcon} src={InfoIcon} alt="Information" />
              </Tooltip>
            )}
          </label>
        </>
      )}

      {type === 'password' ? (
        <div className={[Styles.passInputWrap]}>
          <span
            aria-hidden
            data-state={ipType}
            onClick={() => {
              if (ipType === 'password') {
                setIpType('text');
              } else {
                setIpType('password');
              }
            }}
            className={[Styles.passIcon, Styles[ipType]].join(' ')}
          >
            <img
              alt="show/hide"
              src={showHideIcon && showHideIcon ? showHideIcon : showHideIconDefault}
            />
          </span>
          <DebounceInput
            value={value}
            debounceTimeout={300}
            onChange={ontextchange}
            type={ipType}
            className={[
              Styles.formField,
              errorMessage && Styles.error,
              Styles[radius],
              Styles[border],
              Styles[size],
            ].join(' ')}
            {...rest}
          />
        </div>
      ) : type === 'time' ? (
        <DebounceInput
          value={value}
          defaultValue={defaultValue}
          onChange={onTimeChange}
          type={type}
          className={[
            Styles.formField,
            errorMessage && Styles.error,
            Styles[radius],
            Styles[border],
            Styles[size],
          ].join(' ')}
          {...rest}
        />
      ) : type === 'number' ? (
        <input
          value={value}
          defaultValue={defaultValue}
          max={max}
          onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          min={min}
          onChange={ontextchange}
          type={type}
          className={[
            Styles.formField,
            errorMessage && Styles.error,
            Styles[radius],
            Styles[border],
            Styles[size],
          ].join(' ')}
          {...rest}
        />
      ) : type === 'phone' ? (
        <input
          value={value}
          defaultValue={defaultValue}
          max={max}
          onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          min={min}
          onChange={onphonechange}
          type="number"
          className={[
            Styles.formField,
            errorMessage && Styles.error,
            Styles[radius],
            Styles[border],
            Styles[size],
          ].join(' ')}
          {...rest}
        />
      ) : (
        <DebounceInput
          inputRef={ref}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxLength}
          onChange={ontextchange}
          type={type}
          className={[
            Styles.formField,
            errorMessage && Styles.error,
            Styles[radius],
            Styles[border],
            Styles[size],
          ].join(' ')}
          {...rest}
        />
      )}

      {forgotPassLink && (
        <div className={[Styles.ForgotPass]}>
          <Link title="Forgot Password?" to={forgotPassLink}>
            Forgot Password?
          </Link>
        </div>
      )}

      {errorMessage && <span className={[Styles.error_text]}>{errorMessage}</span>}
    </div>
  );
}

export default InputField;
InputField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  requiredText: PropTypes.string,
  errorMessage: PropTypes.string,
  radius: PropTypes.string,
  border: PropTypes.string,
  size: PropTypes.string,
  maxLength: PropTypes.number,
  margin: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  NumberMax: PropTypes.number,
  forgotPassLink: PropTypes.string,
  showHideIcon: PropTypes.string,
  value: PropTypes.string,
  info: PropTypes.string,
  isFocus: PropTypes.bool,
};

InputField.defaultProps = {
  radius: 'round',
  maxLength: 70,
  border: 'none',
  max: 10000000000,
  min: 0,
  NumberMax: 15,
  size: 'normal',
  margin: '',
  value: '',
  type: '',
  label: '',
  defaultValue: '',
  requiredText: '',
  errorMessage: '',
  forgotPassLink: '',
  showHideIcon: '',
  info: '',
  isFocus: false,
  required: false,
};
