import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Styles from './checkbox.module.scss';

function CheckBox({ label, name, display, position, checked, onChange, ...other }) {
  const [check, setcheck] = useState(checked);
  return (
    <div className={[Styles.checkBoxWrap, Styles[display], Styles[position]].join(' ')}>
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={(e) => {
          setcheck(!check);
          e.stopPropagation();
          onChange(e);
        }}
        {...other}
      />
      {/* {label && <label htmlFor={name}>{label}</label>} */}
      <label htmlFor={name}>{label}</label>
    </div>
  );
}

export default CheckBox;
CheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  display: PropTypes.string,
  position: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
CheckBox.defaultProps = {
  label: '',
  name: '',
  display: 'block',
  position: 'block',
  checked: false,
  onChange: () => {},
};
