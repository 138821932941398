/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';

import Styles from './popup.module.scss';

function Popup({
  isModalOpen,
  closemodal,
  children,
  showCloseBtn,
  modalHeading,
  cancelBtnText,
  saveBtnText,
  saveBtnFunction,
  cancelBtnFunction,
  size,
  type,
}) {
  const [ModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        closemodal();
      }
    }

    if (ModalOpen) {
      // Prevent scolling
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', onKeyDown);
    }

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const closeModal = () => {
    closemodal();
    // uppy.cancelAll();
  };

  if (ModalOpen) {
    return (
      <Portal>
        <div className={Styles.modal__backdrop} />
        <div className={Styles.modal__wrap}>
          <div className={Styles.modal__inner}>
            <div className={[Styles.modal__container, Styles[size], Styles[type]].join(' ')}>
              {showCloseBtn && (
                <span aria-hidden onClick={closeModal} className={Styles.closeBtn}>
                  X
                </span>
              )}
              {modalHeading && <h3 className={Styles.modal__title}>{modalHeading}</h3>}
              <div className="modalContentWrap">
                {type && type === 'fileuploader' ? (
                  <div className={[Styles.ModalContent, Styles.modal__uploader].join(' ')}>
                    {children}
                  </div>
                ) : type === 'material' ? (
                  <div className={Styles.ModalContent}>{children}</div>
                ) : (
                  <div className={Styles.ModalContent}>{children}</div>
                )}
              </div>
              {type && type === 'material' ? (
                ''
              ) : (
                <div className={Styles.ModalFooter}>
                  <button
                    className={[Styles.button, Styles.buttonCancel].join(' ')}
                    type="button"
                    onClick={cancelBtnFunction}
                  >
                    {cancelBtnText && cancelBtnText ? cancelBtnText : 'Cancel'}
                  </button>
                  {saveBtnText && (
                    <button
                      className={[Styles.button, Styles.buttonSave].join(' ')}
                      type="button"
                      onClick={saveBtnFunction}
                    >
                      {saveBtnText}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Portal>
    );
  }

  return '';
}
Popup.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closemodal: PropTypes.bool.isRequired,
  children: PropTypes.element,
  showCloseBtn: PropTypes.bool.isRequired,
  modalHeading: PropTypes.string.isRequired,
  cancelBtnText: PropTypes.string,
  saveBtnText: PropTypes.string.isRequired,
  saveBtnFunction: PropTypes.func.isRequired,
  cancelBtnFunction: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Popup.defaultProps = {
  children: null,
  cancelBtnText: '',
  type: '',
};
export default Popup;
