import React, { useEffect, useState } from 'react';

import Button from 'Components/Button';
import DeletePopup from 'Components/DeletePopup';
import Popup from 'Components/Popup';
import Rating from 'Components/Rating';
import TextArea from 'Components/Textarea';
import Text from 'Components/Typography';
import { formatDate } from 'helper';
import actionIcon from 'images/edit-list.svg';
import deleteicon from 'images/icon-delete.svg';
// import iconFlag from 'images/icon-flag.svg';
// import Cards from "Components/Cards";
// import Button from "Components/Button";
// import Modal from "Components/Modals";
// import FormBuilder from "Components/FormBuilder";
import Styles from './reviews.module.scss';
/* eslint-disable react/prop-types */
function Reviews({ items, size }) {
  return (
    <div className={[Styles.ReviewsWrapper, Styles[size]].join(' ')}>
      {items?.map((item) => (
        <ReviewItem
          key={item?.id}
          reviewId={item?.id}
          reviewHeading={item?.reviewHeading}
          profileName={item?.profileName}
          profileImage={item?.profileImage}
          reviewTitle={item?.reviewTitle}
          reviewDesc={item?.reviewDesc}
          reviewDate={item?.reviewDate}
          reviewRating={item?.reviewRating}
          buttonLabel={item?.buttonLabel}
          buttonAction={item?.buttonAction}
          deleteReview={item?.deleteReview}
          respond={item?.respond}
          deleteComment={item?.deleteComment}
          editComment={item?.editComment}
          errorMessage={item?.errorMessage}
          materialTitle={item?.materialTitle}
          createdAt={item?.createdAt}
          onClickFunction={item?.onClickFunction}
          courseId={item?.courseId}
        />
      ))}
    </div>
  );
}

function ReviewItem({
  profileName,
  reviewHeading,
  profileImage,
  reviewTitle,
  reviewDesc,
  reviewDate,
  reviewRating,
  buttonLabel,
  buttonAction,
  deleteReview,
  reviewId,
  respond,
  deleteComment,
  editComment,
  errorMessage,
  materialTitle,
  createdAt,
  onClickFunction,
  courseId,
}) {
  const [modalOpen, setmodalOpen] = useState(false);
  const [commandbox, setcommandbox] = useState(false);
  const [message, setmessage] = useState({});
  const [commentdelete, setcommentdelete] = useState(false);
  const [commentedit, setcommentedit] = useState(false);
  const [error, seterror] = useState();

  useEffect(() => {
    setmessage({ comment: respond?.[0]?.comment });
  }, []);

  useEffect(() => {
    seterror(errorMessage?.comment);
  }, [errorMessage]);

  const onChange = (name, value) => {
    setmessage({ ...message, [name]: value });
  };
  return (
    <div className={Styles.ReviewItem}>
      <div className={Styles.ReviewHead}>
        <div
          className={Styles.ReviewHeadLeft}
          onClick={onClickFunction ? () => onClickFunction(courseId) : null}
          aria-hidden
        >
          {profileImage ? (
            <div className={Styles.ReviewProfile}>
              <div className={Styles.ProfileImage}>
                <img src={profileImage} alt="profile" />
              </div>
              <div className={Styles.ProfileDetails}>
                <div className={Styles.ProfileName}>{profileName}</div>
                <div className={Styles.ProfileDate}>{reviewDate}</div>
              </div>
            </div>
          ) : (
            <Text type="h4" margin="nomargin">
              {reviewHeading} <br />
            </Text>
          )}
        </div>
        <div className={Styles.ReviewHeadRight}>
          {/* <span className={Styles.flag}>
            <img src={iconFlag} alt="flag" />
          </span> */}
          <span>
            <img aria-hidden src={deleteicon} alt="delete" onClick={() => setmodalOpen(true)} />
          </span>
          {/* <span>
            <img src={iconMore} alt="more" />
          </span> */}
        </div>
      </div>
      <Text type="h4" margin="nomargin">
        {materialTitle && `Material - ${materialTitle}`}
      </Text>
      {createdAt && formatDate(createdAt)}
      <Rating reviewRating={reviewRating} />
      <div className={Styles.ReviewContent}>
        <h4>{reviewTitle}</h4>
        <div className={Styles.ReviewText}>{reviewDesc}</div>
      </div>
      {respond?.[0]?.comment && (
        <div className={Styles.respond_area}>
          <div className={Styles.respond_head}>
            <h5>Response{}</h5>
            <div className={Styles.respond_action}>
              <span>
                <img
                  aria-hidden
                  src={deleteicon}
                  alt="delete"
                  onClick={() => setcommentdelete(true)}
                />
              </span>
              <span>
                <img
                  aria-hidden
                  src={actionIcon}
                  alt="edit"
                  onClick={() => {
                    setcommentedit(true);
                    setmessage({ ...message, comment: respond?.[0]?.comment });
                  }}
                />
              </span>
            </div>
          </div>

          {/* {JSON.stringify(error)} */}
          <p>{respond?.[0]?.comment}</p>
        </div>
      )}
      <div className={Styles.ReviewFooter}>
        {buttonLabel && respond?.length === 0 && (
          <Button onClick={() => setcommandbox(true)} btnColor="secondary">
            {buttonLabel}
          </Button>
        )}
      </div>
      <DeletePopup
        onConfirm={() => {
          deleteReview(reviewId);
        }}
        confirmText="delete"
        onClose={() => {
          setmodalOpen(false);
        }}
        isModalOpen={modalOpen}
      />
      <DeletePopup
        onConfirm={() =>
          deleteComment(reviewId, respond?.[0]?._id, () => {
            setcommentdelete(false);
            setmessage({ comment: '' });
          })
        }
        confirmText="delete"
        onClose={() => {
          setcommentdelete(false);
        }}
        isModalOpen={commentdelete}
      />
      <Popup
        showCloseBtn
        saveBtnText="Respond"
        size="md"
        modalHeading="Respond to"
        isModalOpen={commandbox}
        closemodal={() => {
          setcommandbox(false);
          seterror('');
        }}
        cancelBtnFunction={() => {
          setcommandbox(false);
          seterror('');
        }}
        saveBtnFunction={() => buttonAction(reviewId, message.comment, () => setcommandbox(false))}
      >
        <div className={Styles.ReviewProfile}>
          <div className={Styles.ProfileImage}>
            <img src={profileImage} alt="profile" />
          </div>
          <div className={Styles.ProfileDetails}>
            <div className={Styles.ProfileName}>{profileName}</div>
            <div className={Styles.ProfileDate}>{reviewDate}</div>
          </div>
        </div>
        <br />
        <p>
          <TextArea label="Message" name="comment" onChange={onChange} errorMessage={error} />
        </p>
      </Popup>
      <Popup
        showCloseBtn
        saveBtnText="Respond"
        size="md"
        modalHeading="Respond to"
        isModalOpen={commentedit}
        closemodal={() => {
          setcommentedit(false);
          seterror('');
        }}
        cancelBtnFunction={() => {
          setcommentedit(false);
          seterror('');
        }}
        saveBtnFunction={() =>
          editComment(reviewId, respond?.[0]?._id, message.comment, () => setcommentedit(false))
        }
      >
        <div className={Styles.ReviewProfile}>
          <div className={Styles.ProfileImage}>
            <img src={profileImage} alt="profile" />
          </div>
          <div className={Styles.ProfileDetails}>
            <div className={Styles.ProfileName}>{profileName}</div>
            <div className={Styles.ProfileDate}>{reviewDate}</div>
          </div>
        </div>
        <br />
        <p>
          <TextArea
            label="Message"
            defaultValue={respond?.[0]?.comment}
            name="comment"
            onChange={onChange}
            errorMessage={error}
          />
        </p>
      </Popup>
    </div>
  );
}

export default Reviews;
