import React from 'react';

import ItemIcon2 from 'images/icon-courses.svg';
import ItemIcon1 from 'images/icon-dashboard.svg';
// import ItemIcon6 from 'images/icon-extras.svg';
import ItemIcon9 from 'images/icon-media.svg';
import ItemIcon4 from 'images/icon-performance.svg';
import ItemIcon5 from 'images/icon-question-bank.svg';
import ItemIcon8 from 'images/icon-scheduler.svg';
import ItemIcon7 from 'images/icon-settings.svg';
import ItemIcon3 from 'images/icon-students.svg';
import { PERMISSIONS } from 'utils/permissions';

const Dashboard = React.lazy(() => import('modules/Dashboard'));
const Courses = React.lazy(() => import('modules/Courses'));
const Media = React.lazy(() => import('modules/Media'));
// const Extras = React.lazy(() => import('modules/Extras'));
const Performance = React.lazy(() => import('modules/Performance'));
const QuestionBank = React.lazy(() => import('modules/QuestionBank'));
const Settings = React.lazy(() => import('modules/Settings'));
const Students = React.lazy(() => import('modules/Students'));
const Scheduler = React.lazy(() => import('modules/Scheduler'));

const routes = [
  {
    link: '/dashboard',
    label: 'Dashboard',
    permissionUrl: PERMISSIONS.DASHBOARD,
    imageSrc: ItemIcon1,
    component: Dashboard,
  },
  {
    link: '/courses',
    label: 'Courses',
    permissionUrl: PERMISSIONS.COURSE_LIST,
    imageSrc: ItemIcon2,
    component: Courses,
  },
  {
    link: '/students',
    label: 'Students',
    permissionUrl: PERMISSIONS.STUDENT_VIEW,
    imageSrc: ItemIcon3,
    component: Students,
  },
  {
    link: '/course-performance',
    label: 'Performance',
    permissionUrl: PERMISSIONS.PERFORMANCE,
    imageSrc: ItemIcon4,
    component: Performance,
  },
  {
    link: '/scheduler',
    permissionUrl: PERMISSIONS.SCHEDULER,
    label: 'Scheduler',
    imageSrc: ItemIcon8,
    component: Scheduler,
  },
  {
    link: '/questionbank',
    permissionUrl: PERMISSIONS.QUESTION_BANK_VIEW,
    label: 'Question Bank',
    imageSrc: ItemIcon5,
    component: QuestionBank,
  },
  // {
  //   link: '/extras',
  //   permissionUrl: PERMISSIONS.EXTRA_VIEW,
  //   label: 'Extras',
  //   imageSrc: ItemIcon6,
  //   component: Extras,
  // },
  {
    link: '/settings',
    permissionUrl: PERMISSIONS.SETTINGS_VIEW,
    label: 'Settings',
    imageSrc: ItemIcon7,
    component: Settings,
  },
  {
    link: '/media',
    permissionUrl: PERMISSIONS.MATERIAL_VIEW,
    label: 'Media',
    imageSrc: ItemIcon9,
    component: Media,
  },
];
export default routes;
