import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isEmpty } from 'helper';
import announceIcon from '../../images/announce-icon.svg';
import Styles from './notifications.module.scss';

function Notifications({ items, title, link, linkText, linkPosition }) {
  return (
    <div className={Styles.notiWrapper}>
      <div className={Styles.notiHeading}>
        <h4>{title}</h4>
        {linkPosition && linkPosition === 'right' ? (
          <div className={Styles.notiMore}>
            <Link to={link}>{linkText}</Link>
          </div>
        ) : (
          ''
        )}
      </div>
      <ul>
        {isEmpty(items) && <h3>No student enrolled</h3>}
        {items?.map((item) => (
          <NotiItem
            key={item.id}
            type={item.type}
            title={item.title}
            desc={item.desc}
            time={item.time}
            image={item.image}
            handleOnClick={item.handleOnClick}
            studentId={item.studentId}
          />
        ))}
      </ul>
      {linkPosition && linkPosition === 'bottom' ? (
        <div className={[Styles.notiMore, Styles.bottom].join(' ')}>
          <Link to={link}>{linkText}</Link>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

function NotiItem({ title, desc, time, image, type, handleOnClick, studentId }) {
  return (
    <li>
      <div
        className={Styles.notiContent}
        onClick={() => handleOnClick(studentId, type)}
        aria-hidden
      >
        <div className={Styles.notiImg}>
          {type && type === 'announcement' ? (
            <img src={announceIcon} alt="images" />
          ) : (
            <img src={image} alt="images" />
          )}
        </div>
        <div className={Styles.notiText}>
          {type && type === 'announcement' ? (
            <div className={[Styles.notiTitle, Styles.notiTitleAnnounce].join(' ')}>{title}</div>
          ) : (
            <div className={Styles.notiTitle}>{title}</div>
          )}
          <div className={Styles.notiDesc}>{desc}</div>
        </div>
      </div>
      <div className={Styles.notiTime}>{time}</div>
    </li>
  );
}
Notifications.propTypes = {
  items: PropTypes.arrayOf().isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkPosition: PropTypes.string,
};

Notifications.defaultProps = {
  link: '',
  linkText: '',
  linkPosition: '',
};
NotiItem.propTypes = {
  desc: PropTypes.string,
  title: PropTypes.string,
  time: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  handleOnClick: PropTypes.func,
  studentId: PropTypes.string,
};
NotiItem.defaultProps = {
  desc: '',
  title: '',
  time: '',
  image: '',
  type: '',
  handleOnClick: () => {},
  studentId: '',
};

export default Notifications;
