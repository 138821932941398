import React from 'react';
import PropTypes from 'prop-types';

import Styles from './typography.module.scss';

function Text({ type, size, color, text, children, decoration, weight, margin, ...other }) {
  const classes = [
    Styles[type],
    Styles.Text,
    Styles[color],
    Styles[decoration],
    Styles[weight],
    Styles[size],
    Styles[margin],
  ].join(' ');

  switch (type) {
    case 'h1':
      return (
        <h1 className={classes} {...other}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={classes} {...other}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={classes} {...other}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={classes} {...other}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={classes} {...other}>
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6 className={classes} {...other}>
          {children}
        </h6>
      );
    default:
      return (
        <p className={classes} {...other}>
          {children}
        </p>
      );
  }
}

export default Text;

Text.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'success',
    'warning',
    'dark',
    'light',
    'info',
    'text',
  ]),
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  decoration: PropTypes.oneOf(['strike', 'underline', 'overline']),
  weight: PropTypes.oneOf(['bold', 'semibold', 'medium', 'light', 'normal']),
  margin: PropTypes.oneOf(['nomargin', 'nopadding', 'margintop', 'paddingtop']),
  text: PropTypes.string,
  children: PropTypes.element,
};

Text.defaultProps = {
  color: 'primary',
  type: 'p',
  size: 'md',
  weight: 'normal',
  decoration: '',
  margin: '',
  text: '',
  children: null,
};
