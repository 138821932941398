import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

import Styles from './recaptcha.module.scss';

const RecaptchaModule = ({ errors, onChange, ...others }) => {
  const recaptchaRef = React.createRef();

  return (
    <div className={Styles.captcha_area}>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Ldu-ggbAAAAAEYJUaSHJ7ygUXwWAJZIkUm0_PRL"
        onChange={(value) => onChange('captcha', value)}
        {...others}
      />
      {errors.captcha && (
        <label>
          <span className={Styles.error_text}>Please accept the recaptcha provided</span>
        </label>
      )}
    </div>
  );
};
RecaptchaModule.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape,
};

RecaptchaModule.defaultProps = {
  errors: {},
};

export default RecaptchaModule;
