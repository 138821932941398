import Resizer from 'react-image-file-resizer';
import { useLocation } from 'react-router-dom';
import { Uppy } from '@uppy/core';
import Tus from '@uppy/tus';
import Axios from 'axios';
import moment from 'moment';

export const getActions = (TYPE) => ({
  CREATE: `CREATE_${TYPE}`,
  GETONE: `GET_${TYPE}_DETAILS`,
  GETALL: `GET_${TYPE}`,
  UPDATE: `UPDATE_${TYPE}`,
  DELETE: `DELETE_${TYPE}`,
});
export const geDiscountedAmount = (amount, discount) => amount - amount * (discount / 100);
export const getQuery = () => useLocation().search.split('=')[1];
export const getCurrencySymbol = (value) => {
  switch (value) {
    case 'dollar':
      return '$';
    case 'indianRupee':
      return '₹';
    case 'euro':
      return '€';
    default:
      return '₹';
  }
};

export const getFileDuration = (file) =>
  new Promise((resolve) => {
    const audio = document.createElement('audio');
    const reader = new FileReader();
    let duration = 0;
    reader.onload = (e) => {
      audio.src = e.target.result;
      audio.addEventListener(
        'loadedmetadata',
        () => {
          duration = audio.duration;
          resolve(duration);
        },
        false
      );
    };
    reader.readAsDataURL(file);
  });

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const hasPermission = (data, permission) => {
  if (isEmpty(permission)) {
    return true;
  }
  return permission?.[data?.url]?.filter((v) => v.aclKey === data?.aclKey)[0]?.action === 'allow';
};

export const ClassNames = (classarray) => classarray.join(' ');

/**
 * Get nested prop value of object
 * @param {*} obj
 * @param {*} prop
 * @return {*}
 */
export const getObjectProp = (obj, prop) => {
  if (!obj) return null;

  if (typeof obj === 'string') {
    return obj;
  }
  if (typeof obj === 'undefined') {
    return false;
  }
  const _index = prop.indexOf('.');
  if (_index > -1) {
    return getObjectProp(obj[prop.substring(0, _index)], prop.substr(_index + 1));
  }
  return obj[prop];
};

export const appendToFormData = (datas) => {
  const formData = new FormData();
  Object.keys(datas).forEach((data) => {
    if (datas[data] instanceof Array && datas[data][0] instanceof File) {
      for (let i = 0; i < datas[data].length; i += 1) {
        formData.append(data, datas[data][i]);
      }
    } else if (datas[data] instanceof File || typeof datas[data] !== 'object') {
      formData.append(data, datas[data]);
    } else if (datas[data] instanceof Date && datas[data].getTime()) {
      formData.append(data, datas[data]);
    } else {
      formData.append(data, JSON.stringify(datas[data]));
    }
  });
  return formData;
};

/**
 * resizeFile
 * @param {*} file
 */
export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

/**
 * get Report
 * @param {*} url
 * @param {*} params
 */
export function getCsvReport(url, params) {
  Axios({
    url,
    params,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const downloadurl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadurl;
    const fileName = `${+new Date()}.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  });
}

export function currentMonthDates() {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);
  const data = { firstday: firstDay, lastday: lastDay };
  return data;
}

export function getComparedArrayValues(firstArray, secondArray) {
  const lastArray = [];
  if (!isEmpty(firstArray) && !isEmpty(secondArray)) {
    // eslint-disable-next-line array-callback-return
    firstArray.filter((x) => {
      if (secondArray.indexOf(x) >= 0) {
        return lastArray.push({ id: x, done: true });
      }
      lastArray.push({ id: x, done: false });
    });
  }
  return !isEmpty(lastArray) ? lastArray : firstArray;
}

export const formateTime = (date, time) => {
  const d = new Date(date);
  const hour = time.split(':')[0];
  const minute = time.split(':')[1];
  d.setHours(hour);
  d.setMinutes(minute);
  return new Date(d).toISOString();
};

export const getTimePickerValue = (time) => {
  if (
    `${new Date(time).getHours()}`.length === 1 &&
    `${new Date(time).getMinutes()}`.length === 1
  ) {
    return `0${new Date(time).getHours()}:0${new Date(time).getMinutes()}`;
  }
  if (
    `${new Date(time).getHours()}`.length === 1 &&
    `${new Date(time).getMinutes()}`.length === 2
  ) {
    return `0${new Date(time).getHours()}:${new Date(time).getMinutes()}`;
  }
  if (
    `${new Date(time).getHours()}`.length === 2 &&
    `${new Date(time).getMinutes()}`.length === 1
  ) {
    return `${new Date(time).getHours()}:0${new Date(time).getMinutes()}`;
  }
  if (
    `${new Date(time).getHours()}`.length === 2 &&
    `${new Date(time).getMinutes()}`.length === 2
  ) {
    return `${new Date(time).getHours()}:${new Date(time).getMinutes()}`;
  }
};
/**
 * parseISOString to date
 * @param {*} s
 */
export const parseISOString = (s) => {
  if (!s || typeof s !== 'string') return s;
  const b = s.split(/\D+/);
  b[1] -= 1;
  return new Date(Date.UTC(b[0], b[1], b[2], b[3], b[4], b[5], b[6]));
};

/**
 * Format date
 * @param {*} date
 * @param {*} time
 */
export const formatDate = (date, time = true) => {
  if (!date || (date && typeof date === 'undefined')) {
    return '';
  }
  const newDate = parseISOString(date);
  if (typeof newDate.getMonth !== 'function') return date;
  // const newDate = new Date(date);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const day = newDate.getDate();
  const monthIndex = newDate.getMonth();
  const year = newDate.getFullYear();
  if (!time) return `${monthNames[monthIndex]} ${day} , ${+year}`;

  const hrs = newDate.getHours();
  const hour = hrs % 12;
  const minutes = newDate.getMinutes();
  return `${monthNames[monthIndex]} ${day} , ${+year}  at  ${hour === 0 ? 12 : hour}:${
    minutes < 10 ? `0${minutes}` : minutes
  }${hrs / 12 >= 1 ? 'PM' : 'AM'}`;
};

export const convertTImeStamp = (data) => {
  const temp = moment.utc(data).format('MM/DD/YYYY');
  const date = moment(temp).add(1, 'd');
  return new Date(date);
};

export const emailTrim = (email) => email.trim()?.toLowerCase();

export const capitalise = (str) => str[0].toUpperCase() + str.slice(1);

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  // eslint-disable-next-line radix
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  // eslint-disable-next-line no-restricted-properties
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
};

export const imagePreview = (mediaStorageType, fileName, filePath = '') => {
  let url;
  if (mediaStorageType === 'FileStore') {
    url = `${process.env.REACT_APP_BASE_URL}${filePath}/${fileName}`;
  } else if (mediaStorageType === 'S3Storage') {
    url = `${process.env.REACT_APP_AWS_BASE_URL}${filePath}/${fileName}`;
  } else if (mediaStorageType === 'frontEnd') {
    if (filePath) {
      url = `${process.env.REACT_APP_FRONTEND_BASE_URL}${filePath}/${fileName}`;
    } else {
      url = `${process.env.REACT_APP_FRONTEND_BASE_URL}${fileName}`;
    }
  } else if (mediaStorageType === 'mobileServer') {
    url = `${process.env.REACT_APP_MOBILE_SERVER_BASE_URL}${filePath}/${fileName}`;
  }
  return url;
};
export const imagePreviewtemp = (data) => {
  let url;
  if (data?.mediaStorageType === 'FileStore') {
    url = `${process.env.REACT_APP_BASE_URL}${data?.imagePath}/${data?.imageName}`;
  } else if (data?.mediaStorageType === 'S3Storage') {
    url = `${process.env.REACT_APP_AWS_BASE_URL}${data?.imagePath}/${data?.imageName}`;
  } else if (data?.mediaStorageType === 'frontEnd') {
    if (data?.imagePath) {
      url = `${process.env.REACT_APP_FRONTEND_BASE_URL}${data?.imagePath}/${data?.imageName}`;
    } else {
      url = `${process.env.REACT_APP_FRONTEND_BASE_URL}${data?.imageName}`;
    }
  }
  return url;
};
// export const imagePreviewtemp1 = (data) => {
//   let url;
//   if (data?.storageType === 'FileStore') {
//     url = `${process.env.REACT_APP_BASE_URL}${data?.filePath}/${data?.fileName}`;
//   } else if (data?.mediaStorageType === 'S3Storage') {
//     url = `${process.env.AWS_BASE_URL}${data?.profileImagePath}/${data?.profileImageName}`;
//   } else if (data?.mediaStorageType === 'frontEnd') {
//     url = `${process.env.REACT_APP_FRONTEND_BASE_URL}${data?.profileImageName}`;
//   }
//   return url;
// };

export const arrayOfObjectIsEmpty = (array) => array.every((val) => Object.keys(val).length === 0);

export const maskValidation = (data, fields) => {
  const error = {};
  fields.forEach((field) => {
    if (data?.[field.name].includes('*****')) {
      error[field.name] = 'Invalid Keys';
    }
  });
  return error;
};

export const secondsToHhmmssFormat = (secs) => {
  const sec = parseInt(secs, 10);
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor(sec / 60) % 60;
  const seconds = sec % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

const getType = (file) => {
  if (file === 'document') return 'application/pdf';
  return `${file}/*`;
};

export const getUppyFactory = (file) =>
  new Uppy({
    autoProceed: false,
    restrictions: {
      allowedFileTypes: [getType(file)],
    },
  }).use(Tus, {
    resume: true,
    endpoint: '/api/v1.0/uploads',
    //   retryDelays: [0, 3000, 5000, 10000, 20000],
    // chunkSize: 1000000,
  });

export const getMonth = (number) => {
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  return months[number - 1];
};
export const getDay = (month, day) => {
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  return `${months[month - 1]} ${day}`;
};

export const getWeekDay = (number) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[number];
};

export const SecondsToHms = (a) => {
  const d = Number(a);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' h ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' min ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' sec') : '';
  return hDisplay + mDisplay + sDisplay;
};

export const checkHtmlContent = (data) => {
  const isEmptys = isEmpty(data?.replace(/<(.|\n)*?>/g, '')?.replaceAll('&nbsp;', ''));
  return isEmptys;
};

const getTimeAsNumberOfMinutes = (time) => {
  const timeParts = time?.split(':');

  const timeInMinutes = timeParts?.[0] * 60 + timeParts?.[1];

  return Number(timeInMinutes);
};

export const checkTime = (time1, time2) => {
  const time1InMinutesForTime1 = getTimeAsNumberOfMinutes(time1);
  const time1InMinutesForTime2 = getTimeAsNumberOfMinutes(time2);
  return time1InMinutesForTime1 < time1InMinutesForTime2;
};

export const dateFormat = (date) => {
  if (date) {
    return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(
      date
    ).getFullYear()}`;
  }
  return null;
};
