import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './deletepopup.module.scss';

const DeleteModal = ({ isModalOpen, onConfirm, onClose, confirmText, warningMessage }) => {
  const ref = useRef();
  const [confirmLabel, setconfirmLabel] = useState('');
  const handleChange = ({ target }) => {
    setconfirmLabel(target.value);
  };
  useEffect(() => {
    ref?.current?.focus();
  }, [isModalOpen]);
  const handleClose = () => {
    // e.stopPropagation();
    onClose();
  };
  const handleConfirm = (e) => {
    e.stopPropagation();
    if ((confirmText || 'confirm') === confirmLabel) {
      onConfirm();
      setconfirmLabel('');
    }
  };
  if (isModalOpen)
    return (
      <div aria-hidden className={styles.modalContainer} onClick={handleClose}>
        <div aria-hidden className={styles.modal} onClick={(e) => e.stopPropagation()}>
          <p>Confirm Action</p>
          <p>
            To perform this action enter the confirmation text
            <i>
              <b style={{ marginLeft: 10 }}>{confirmText || ' confirm'}</b>
            </i>
          </p>
          {warningMessage && <p className={styles.warning}>{warningMessage}</p>}
          <input
            ref={ref}
            placeholder={confirmText || 'confirm'}
            type="text"
            onClick={(e) => e.stopPropagation()}
            value={confirmLabel}
            onChange={handleChange}
          />
          <div className={styles.button_area}>
            <button type="button" onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
            <button
              type="button"
              className={styles.confirm}
              disabled={(confirmText || 'confirm') !== confirmLabel}
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  return null;
};
DeleteModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  warningMessage: PropTypes.string,
};
DeleteModal.defaultProps = {
  warningMessage: '',
};
export default DeleteModal;
