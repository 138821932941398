import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import setAuthToken from 'setAuthToken';

import { logoutUser, setCurrentUser } from 'actions/auth';
import Nodata from 'Components/Nodata';
import Dashboard from 'modules/Dashboard';
import ForgotPassword from 'pages/ForgotPassword';
import Login from 'pages/Login';
import NewPassword from 'pages/NewPassword';
import NewUser from 'pages/NewUser';
import routes from 'routes';
import storage from 'storage';
import store from 'store';
import { PERMISSIONS } from 'utils/permissions';
import AuthRouter from './authRouter';
// eslint-disable-next-line no-unused-vars
import firebase from './firebase';

import '@uppy/core/dist/style.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@uppy/dashboard/src/style.scss';
import '@uppy/progress-bar/src/style.scss';
import './App.scss';

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwtDecode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
  }
}
const App = () => {
  useEffect(() => {
    if (localStorage.jwtToken) {
      storage.getAndStoreAllItems();
    }
  }, localStorage.jwtToken);
  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/ForgotPassword" exact>
        <ForgotPassword />
      </Route>
      <Route path="/resetPassword" exact>
        <NewPassword />
      </Route>
      <Route path="/newUser" exact>
        <NewUser />
      </Route>
      <AuthRouter path="/" component={Dashboard} permissionUrl={PERMISSIONS.DASHBOARD} exact />
      <AuthRouter path="/nodata" component={Nodata} exact />
      {routes.map(({ link, component: Component, permissionUrl }) => (
        <AuthRouter path={link} component={Component} key={link} permissionUrl={permissionUrl} />
      ))}
    </Switch>
  );
};

export default withRouter(App);
