import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tooltip } from '@acodez/tooltip';
import PropTypes from 'prop-types';

import actions, { toggleDrawer } from 'actions';
// import { ToastContextProvider } from 'Components/ToastContextProvider';
import { logoutUser } from 'actions/auth';
import Logo from 'Components/Logo';
import NavList from 'Components/Menu';
import Navbar from 'Components/Navbar';
import PageWrap from 'Components/PageWrap';
import Popup from 'Components/Popup';
import { ClassNames, getActions, imagePreview } from 'helper';
import avatar from 'images/boyavatar.svg';
import logoutIcon from 'images/icon-logout.svg';
import notificationIcon from 'images/icon-notification.svg';
import logoImage from 'images/logo.svg';
import logoIcon from 'images/logoIcon.svg';
import logoIconLight from 'images/logoIconlight.svg';
import logoImageLight from 'images/logolight.svg';
import routes from 'routes';

function Wrapper(props) {
  const {
    isOpen,
    profile,
    getData,
    children,
    history,
    toggleDrawer,
    logoutUser,
    notiificationCount,
  } = props;

  const [popup, setpopup] = useState(false);
  // const { mediaStorageType, profileImageName, profileImagePath } = profile;
  useEffect(() => {
    const action = getActions('PROFILE');
    getData('settings/profile', action.GETONE);
  }, []);
  const handleSize = () => {
    toggleDrawer();
  };

  const handlelogout = () => {
    logoutUser();
  };

  return (
    // <ToastContextProvider position="bottom_center">
    <>
      <PageWrap size={isOpen ? 'small' : 'large'} className={isOpen ? 'small' : 'large'}>
        <div className="sidebar">
          <button type="button" aria-label=" " className="sidebar_btn" onClick={handleSize} />
          <div className="sidebar_area">
            <div className="navWrap">
              <Navbar theme="dark" direction="vertical" size={isOpen ? 'small' : 'large'}>
                <Logo
                  logoIcon={logoIcon}
                  logoFull={logoImage}
                  logoIconLight={logoIconLight}
                  logoFullLight={logoImageLight}
                  visibleLogo={isOpen ? 'icon' : 'full'}
                  position="block"
                />
                {/* <SearchField
                  searchIcon={searchIcon}
                  placeholder="Search"
                  mode={size ? 'small' : 'large'}
                /> */}
                <NavList theme="dark" hoverStyle="button" items={routes} mode="xs" />
              </Navbar>
            </div>
            <div className={ClassNames(['dashTools', isOpen && 'small'])}>
              {/* {alert(JSON.stringify(profile?.data?.mediaStorageType))} */}
              <Tooltip text="Profile" position="top">
                <div
                  className="sidebarImage"
                  aria-hidden
                  onClick={() => history.push('/settings/profile')}
                >
                  <img
                    src={
                      (profile?.data?.profileImageName &&
                        imagePreview(
                          profile?.data?.mediaStorageType,
                          profile?.data?.profileImageName,
                          profile?.data?.profileImagePath
                        )) ||
                      avatar
                    }
                    alt="profile"
                  />
                </div>
              </Tooltip>
              <Tooltip text="Notification" position="top">
                <div
                  className="dashToolItem"
                  onClick={() => history.push('/dashboard/notification')}
                  aria-hidden
                >
                  <img src={notificationIcon} alt="notification" aria-hidden />
                  <span className="notiCount">{notiificationCount?.count}</span>
                </div>
              </Tooltip>
              <Tooltip text="Logout" position="top">
                <div className="dashToolItem">
                  <img aria-hidden src={logoutIcon} alt="logout" onClick={() => setpopup(true)} />
                </div>
              </Tooltip>
            </div>
            {isOpen && (
              <div className="copyright">
                Powered by{' '}
                <a
                  href="https://acodez.in"
                  target="_blank"
                  rel="nofollow noreferrer"
                  title="Developed by Acodez"
                >
                  Acodez
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="main">{children}</div>
      </PageWrap>
      <Popup
        showCloseBtn
        saveBtnText="Ok"
        size="md"
        modalHeading="Confirm Logout"
        isModalOpen={popup}
        closemodal={() => setpopup(false)}
        cancelBtnFunction={() => setpopup(false)}
        saveBtnFunction={handlelogout}
      >
        <p>Are you sure you want to logout?</p>
      </Popup>
    </>
    // </ToastContextProvider>
  );
}

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  isOpen: state.sidebar?.isOpen,
  profile: state.profile_details,
  notiificationCount: state.notificationReducer,
});

Wrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  notiificationCount: PropTypes.shape,
  profile: PropTypes.shape.isRequired,
  getData: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

Wrapper.defaultProps = {
  notiificationCount: {},
};
export default withRouter(
  connect(mapStateToProps, { logoutUser, toggleDrawer, ...actions })(Wrapper)
);
