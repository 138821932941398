import React, { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ClassNames, isEmpty } from 'helper';
import Styles from './toast.module.scss';

const ToastContext = createContext();

export default ToastContext;

export function ToastContextProvider({ position, children }) {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (!isEmpty(toasts)) {
      const timer = setTimeout(() => setToasts((toasts) => toasts.slice(1)), 4000);
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    (toast) => {
      setToasts((toasts) => [...toasts, toast]);
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {/* {!isEmpty(toasts) && ( */}
      <div className={ClassNames([Styles.toast_area, Styles[position]])}>
        {toasts?.map((toast) => (
          <Toast
            key={toast.key}
            theme={toast.theme}
            pauseOnHover={toast.pauseOnHover}
            progressbar={toast.progressbar}
            closeOnClick={toast.closeOnClick}
            message={toast.message}
          />
        ))}
      </div>
      {/* )} */}
    </ToastContext.Provider>
  );
}
function Toast({ theme, autoClose, progressbar, closeOnClick, message, pauseOnHover }) {
  return (
    <div
      className={ClassNames([Styles.toast_box, Styles[theme], pauseOnHover && Styles.pause])}
      autoClose={autoClose}
    >
      <span>{message}</span>
      {progressbar && <div className={Styles.progress} />}
      {closeOnClick && (
        <button type="button" className={Styles.close}>
          x
        </button>
      )}
    </div>
  );
}

ToastContextProvider.propTypes = {
  // notification: PropTypes.arrayOf().isRequired,
  position: PropTypes.string,
  children: PropTypes.element,
};
ToastContextProvider.defaultProps = {
  position: 'bottom_center',
  children: null,
};
Toast.propTypes = {
  message: PropTypes.string,
  theme: PropTypes.string,
  progressbar: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  autoClose: PropTypes.bool,
};
Toast.defaultProps = {
  theme: 'primary',
  message: '',
  progressbar: true,
  closeOnClick: true,
  pauseOnHover: true,
  autoClose: false,
};
