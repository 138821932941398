import React from 'react';
import PropTypes from 'prop-types';

import Styles from './rating.module.scss';

export default function Rating({ reviewRating }) {
  return (
    <div className={Styles.rating_area}>
      <div className={Styles.ReviewRating}>
        <div className={Styles.RatingCount}>{reviewRating}/5</div>
        <div className={Styles.RatingStars}>
          {[...Array(Math.max(0, reviewRating))].map(() => (
            <i className={Styles.fill} />
          ))}
          {[...Array(5 - Math.max(0, reviewRating))].map(() => (
            <i />
          ))}
        </div>
      </div>
    </div>
  );
}
Rating.propTypes = {
  reviewRating: PropTypes.number,
};

Rating.defaultProps = {
  reviewRating: 0,
};
