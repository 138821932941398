// eslint-disable-next-line import/prefer-default-export
export const PERMISSIONS = {
  COURSE_URL: 'Courses',
  STUDENT_URL: 'Students',
  LIVEEXAM_URL: 'Live Exam',
  PREVIOUS_YEAR_EXAM_URL: 'Previous Year Exam',
  SETTINGS_URL: 'Settings',
  QUESTIONBANK_URL: 'Question Bank',
  DASHBOARD: {
    aclKey: 'dashboard',
    type: 'disabled',
    url: 'Dashboard',
  },
  COURSE_ADD: {
    aclKey: 'course.add',
    type: 'hidden',
    url: 'Courses',
  },
  COURSE_EDIT: {
    aclKey: 'course.edit',
    type: 'disabled',
    url: 'Courses',
  },
  COURSE_VIEW: {
    aclKey: 'course.view',

    type: 'disabled',
    url: 'Courses',
  },
  COURSE_STUDENT: {
    aclKey: 'course.student',

    type: 'disabled',
    url: 'Courses',
  },
  COURSE_LIST: {
    aclKey: 'course.list',
    type: 'disabled',
    url: 'Courses',
  },
  COURSE_REVIEW: {
    aclKey: 'course.review.list',
    type: 'disabled',
    url: 'Course Review',
  },
  COURSE_DELETE: {
    aclKey: 'course.delete',

    type: 'disabled',
    url: 'Courses',
  },
  COURSE_PUBLISH: {
    aclKey: 'course.publish',

    type: 'disabled',
    url: 'Courses',
  },
  COURSE_UPDATESTATUS: {
    aclKey: 'course.updateStatus',

    type: 'disabled',
    url: 'Courses',
  },
  SUBJECT_ADD: {
    aclKey: 'course.subject.add',
    type: 'disabled',
    url: 'Subject',
  },
  SUBJECT_EDIT: {
    aclKey: 'course.subject.edit',
    type: 'disabled',
    url: 'Subject',
  },
  SUBJECT_LIST: {
    aclKey: 'course.subject.list',
    type: 'disabled',
    url: 'Subject',
  },
  SUBJECT_DELETE: {
    aclKey: 'course.subject.delete',
    type: 'disabled',
    url: 'Subject',
  },
  CHAPTER_ADD: {
    aclKey: 'course.chapter.add',

    type: 'disabled',
    url: 'Chapter',
  },
  CHAPTER_EDIT: {
    aclKey: 'course.chapter.edit',

    type: 'disabled',
    url: 'Chapter',
  },
  CHAPTER_VIEW: {
    aclKey: 'course.chapter.view',

    type: 'disabled',
    url: 'Chapter',
  },
  CHAPTER_DELETE: {
    aclKey: 'course.chapter.delete',

    type: 'disabled',
    url: 'Chapter',
  },
  PLAN_ADD: {
    aclKey: 'course.plan.add',
    type: 'disabled',
    url: 'Plan',
  },
  PLAN_EDIT: {
    aclKey: 'course.plan.edit',

    type: 'disabled',
    url: 'Plan',
  },
  PLAN_VIEW: {
    aclKey: 'course.plan.view',

    type: 'disabled',
    url: 'Plan',
  },
  PLAN_LIST: {
    aclKey: 'course.plan.list',

    type: 'disabled',
    url: 'Plan',
  },
  PLAN_DELETE: {
    aclKey: 'course.plan.delete',

    type: 'disabled',
    url: 'Plan',
  },
  QUESTION_BANK_ADD: {
    aclKey: 'questionbank.add',

    type: 'disabled',
    url: 'Question Bank',
  },
  QUESTION_BANK_EDIT: {
    aclKey: 'questionbank.edit',

    type: 'disabled',
    url: 'Question Bank',
  },
  QUESTION_BANK_VIEW: {
    aclKey: 'questionbank.view',

    type: 'disabled',
    url: 'Question Bank',
  },
  QUESTION_BANK_LIST: {
    aclKey: 'questionbank.list',

    type: 'disabled',
    url: 'Question Bank',
  },
  QUESTION_BANK_DELETE: {
    aclKey: 'questionbank.delete',

    type: 'disabled',
    url: 'Question Bank',
  },
  QUESTION_BANK_DELETEOPTION: {
    aclKey: 'questionbank.deleteoption',

    type: 'disabled',
    url: 'Question Bank',
  },
  QUESTION_BANK_UPLOADIMAGE: {
    aclKey: 'questionbank.uploadimage',

    type: 'disabled',
    url: 'Question Bank',
  },
  QUESTION_BANK_BULKUPLOAD: {
    aclKey: 'questionbank.bulkupload',

    type: 'disabled',
    url: 'Question Bank',
  },
  BULKUPLOAD: {
    aclKey: 'bulkupload',
    url: 'Bulk Upload',
  },
  PERFORMANCE: {
    aclKey: 'course.performance',
    type: 'hidden',
    url: 'Performance',
  },
  SCHEDULER: {
    aclKey: 'scheduler.tasks',
    type: 'disabled',
    url: 'Scheduler',
  },
  EXTRA_VIEW: {
    aclKey: 'extras.view',

    type: 'disabled',
    url: 'Extras',
  },
  SETTINGS_VIEW: {
    aclKey: 'settings.view',

    type: 'disabled',
    url: 'Settings',
  },
  PROFILE_VIEW: {
    aclKey: 'settings.profile.view',
    type: 'disabled',
    url: 'Profile',
  },
  SETTINGS_EMAIL_TEMPLATE: {
    aclKey: 'settings.emailtemplates.view',
    type: 'disabled',
    url: 'Email Template',
  },
  MATERIAL_VIEW: {
    aclKey: 'material.view',
    type: 'disabled',
    url: 'Media',
  },
  COURSEMEDIA_LIST: {
    aclKey: 'course.material.list',

    type: 'disabled',
    url: 'Course Media',
  },
  PREVIOUS_YEAR_EXAM_LIST: {
    aclKey: 'previousyearexam.list',
    type: 'disabled',
    url: 'Previous Year Exam',
  },
  PREVIOUS_YEAR_EXAM_ADD: {
    aclKey: 'previousyearexam.add',
    type: 'disabled',
    url: 'Previous Year Exam',
  },
  PREVIOUS_YEAR_EXAM_EDIT: {
    aclKey: 'previousyearexam.edit',
    type: 'disabled',
    url: 'Previous Year Exam',
  },
  PREVIOUS_YEAR_EXAM_DELETE: {
    aclKey: 'previousyearexam.delete',
    type: 'disabled',
    url: 'Previous Year Exam',
  },
  LIVEEXAM_LIST: {
    aclKey: 'liveexam.list',
    type: 'disabled',
    url: 'Live Exam',
  },
  LIVECLASS_LIST: {
    aclKey: 'liveclass.list',
    type: 'disabled',
    url: 'Live Class',
  },
  LIVECLASS_DELETE: {
    aclKey: 'liveclass.delete',
    type: 'disabled',
    url: 'Live Class',
  },
  LIVECLASS_ADD: {
    aclKey: 'liveclass.add',
    type: 'disabled',
    url: 'Live Class',
  },
  LIVECLASS_PUBLISH: {
    aclKey: 'liveclass.publish',
    type: 'disabled',
    url: 'Live Class',
  },
  LIVECLASS_EDIT: {
    aclKey: 'liveclass.edit',
    type: 'disabled',
    url: 'Live Class',
  },
  LIVEEXAM_ADD: {
    aclKey: 'liveexam.add',
    type: 'disabled',
    url: 'Live Exam',
  },
  LIVEEXAM_EDIT: {
    aclKey: 'liveexam.edit',

    type: 'disabled',
    url: 'Live Exam',
  },
  LIVEEXAM_DELETE: {
    aclKey: 'liveexam.delete',

    type: 'disabled',
    url: 'Live Exam',
  },
  LIVEEXAM_ASSIGN_QUESTION: {
    aclKey: 'liveexam.assignquestion',

    type: 'disabled',
    url: 'Live Exam',
  },
  LIVEEXAM_REMOVE_QUESTION: {
    aclKey: 'liveexam.removequestion',
    type: 'disabled',
    url: 'Live Exam',
  },
  LIVEEXAM_QUESTION_LIST: {
    aclKey: 'liveexam.questions',

    type: 'disabled',
    url: 'Live Exam',
  },
  LIVEEXAM_PUBLISH: {
    aclKey: 'liveexam.publish',

    type: 'disabled',
    url: 'Live Exam',
  },
  LIVEEXAM_PAID: {
    aclKey: 'liveexam.paid',

    type: 'disabled',
    url: 'Live Exam',
  },
  COUPON_LIST: {
    aclKey: 'course.coupon.list',
    type: 'disabled',
    url: 'Coupon',
  },
  SUBUSER_LIST: {
    aclKey: 'settings.users.list',
    type: 'disabled',
    url: 'Sub User',
  },
  SUBUSER_ADD: {
    aclKey: 'settings.users.add',
    type: 'disabled',
    url: 'Sub User',
  },
  SUBUSER_EDIT: {
    aclKey: 'settings.users.edit',
    type: 'disabled',
    url: 'Sub User',
  },
  SUBUSER_DELETE: {
    aclKey: 'settings.users.delete',
    type: 'disabled',
    url: 'Sub User',
  },
  ROLE_LIST: {
    aclKey: 'settings.roles.list',
    type: 'disabled',
    url: 'Role',
  },
  ROLE_ADD: {
    aclKey: 'settings.roles.add',
    type: 'disabled',
    url: 'Role',
  },
  ROLE_EDIT: {
    aclKey: 'settings.roles.edit',
    type: 'disabled',
    url: 'Role',
  },
  ROLE_DELETE: {
    aclKey: 'settings.roles.delete',
    type: 'disabled',
    url: 'Role',
  },
  SETTINGS_SUBUSER: {
    aclKey: 'settings.users.add',
    type: 'disabled',
    url: 'Sub User',
  },
  CMS_LIST: {
    aclKey: 'settings.cms.list',
    type: 'disabled',
    url: 'Cms',
  },
  FAQ_LIST: {
    aclKey: 'settings.faq.list',
    type: 'disabled',
    url: 'Faq',
  },
  STUDENT_BULK_ADD: {
    aclKey: 'student.bulk',

    type: 'disabled',
    url: 'Students',
  },
  STUDENT_ADD: {
    aclKey: 'student.add',
    type: 'disabled',
    url: 'Students',
  },
  STUDENT_VIEW: {
    aclKey: 'student.view',
    type: 'hidden',
    url: 'Students',
  },
  STUDENT_EXPORT: {
    aclKey: 'student.export',

    type: 'disabled',
    url: 'Students',
  },
  STUDENT_EDIT: {
    aclKey: 'student.edit',

    type: 'disabled',
    url: 'Students',
  },
  STUDENT_DELETE: {
    aclKey: 'student.delete',

    type: 'disabled',
    url: 'Students',
  },
  STUDENT_MAIL: {
    aclKey: 'student.mail',

    type: 'disabled',
    url: 'Students',
  },
  STUDENT_NOTIFICATION: {
    aclKey: 'student.notification',

    type: 'disabled',
    url: 'Students',
  },
  STUDENT_UPDATE_PROFILE_IMAGE: {
    aclKey: 'student.updateprofileimage',

    type: 'disabled',
    url: 'Students',
  },
  STUDENT_ANALYTICS: {
    aclKey: 'student.analytics',

    type: 'hidden',
    url: 'Student Analytics',
  },
  STUDENT_COURSE: {
    aclKey: 'student.courses',

    type: 'disabled',
    url: 'Student Course',
  },
  STUDENT_REVIEW: {
    aclKey: 'student.reviews.list',

    type: 'disabled',
    url: 'Student Review',
  },
  STUDENT_TRANSACTION: {
    aclKey: 'student.transactions.list',

    type: 'disabled',
    url: 'Student Transaction',
  },
  MEDIA_ADD: {
    aclKey: 'material.add',

    type: 'disabled',
    url: 'Media',
  },
  MEDIA_DELETE: {
    aclKey: 'material.delete',

    type: 'disabled',
    url: 'Media',
  },
  MEDIA_UNLINK: {
    aclKey: 'material.unlink',

    type: 'disabled',
    url: 'Media',
  },
  MEDIA_PLACE_HOLDER: {
    aclKey: 'material.placeholderimage',

    type: 'disabled',
    url: 'Media',
  },
  MEDIA_COUNT: {
    aclKey: 'material.count',

    type: 'disabled',
    url: 'Media',
  },
  COURSE_MEDIA_ADD: {
    aclKey: 'course.material.add',

    type: 'disabled',
    url: 'Course Media',
  },
  COURSE_MEDIA_LIST: {
    aclKey: 'course.material.list',
    type: 'disabled',
    url: 'Course Media',
  },
  COURSE_MEDIA_DELETE: {
    aclKey: 'course.material.delete',

    type: 'disabled',
    url: 'Course Media',
  },
  COURSE_MEDIA_FEATURED: {
    aclKey: 'course.material.featured',

    type: 'disabled',
    url: 'Course Media',
  },
  COURSE_MEDIA_PAID: {
    aclKey: 'course.material.paid',

    type: 'disabled',
    url: 'Course Media',
  },
  COURSE_MEDIA_COUNT: {
    aclKey: 'course.material.count',

    type: 'disabled',
    url: 'Course Media',
  },
  PROFILE_EDIT: {
    aclKey: 'settings.profile.edit',

    type: 'disabled',
    url: 'Profile',
  },
  PROFILE_IMAGE_EDIT: {
    aclKey: 'settings.profile.image.edit',

    type: 'disabled',
    url: 'Profile',
  },
  PROFILE_PASSWORD_EDIT: {
    aclKey: 'settings.profile.password.edit',

    type: 'disabled',
    url: 'Profile',
  },
  PROFILE_IMAGE_DELETE: {
    aclKey: 'settings.profile.image.delete',

    type: 'disabled',
    url: 'Profile',
  },
  EMAIL_TEMPLATE_ADD: {
    aclKey: 'settings.emailtemplates.add',

    type: 'disabled',
    url: 'Email Template',
  },
  EMAIL_TEMPLATE_EDIT: {
    aclKey: 'settings.emailtemplates.edit',

    type: 'disabled',
    url: 'Email Template',
  },
  EMAIL_TEMPLATE_DELETE: {
    aclKey: 'settings.emailtemplates.delete',

    type: 'disabled',
    url: 'Email Template',
  },
};
