// eslint-disable-next-line import/no-extraneous-dependencies
// import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import Axios from 'axios';
import { messaging } from 'init-fcm';

// import { getActions } from 'helper';
import store from 'store';
// import store from 'store';
import Actions from './types';

function getData(url, type, params) {
  return {
    type,
    payload: {
      request: {
        method: 'GET',
        url,
        params,
      },
    },
  };
}

function createData(url, type, data) {
  return {
    type,
    payload: {
      request: {
        method: 'POST',
        url,
        data,
      },
    },
  };
}

function updateData(url, type, data) {
  return {
    type,
    payload: {
      request: {
        method: 'PUT',
        url,
        data,
      },
    },
  };
}

function removeData(url, type, data) {
  return {
    type,
    payload: {
      request: {
        method: 'DELETE',
        url,
        data,
      },
    },
  };
}

function resetData(type) {
  return {
    type,
  };
}

export const toggleDrawer = (value) => (dispatch) => {
  dispatch({
    type: Actions.IS_DRAWER_OPEN,
    payload: value,
  });
};

const actions = {
  getData,
  resetData,
  removeData,
  updateData,
  createData,
};

export const requestFirebasePermission = () => () => {
  if (messaging) {
    messaging
      .requestPermission()
      .then(async () => {
        const firebaseToken = await messaging.getToken();
        console.log(firebaseToken);
        Axios.put('/settings/profile', {
          notificationToken: firebaseToken,
        })
          .then(() => {})
          .catch(() => {});
      })
      .catch(() => {});
    navigator.serviceWorker.addEventListener('message', (message) => {
      console.log('message');
      console.log(message);
      console.log('message');
      store.dispatch({
        type: Actions.NEW_NOTIFICATION,
        payload: message?.data['firebase-messaging-msg- ']?.notification?.title,
      });
      Axios.get('dashboard/notifications')
        .then((result) => {
          store.dispatch({
            type: Actions.DASHBOARD_NOTIFICATION,
            payload: result,
          });
        })
        .catch(() => {});
    });
  }
};

export default actions;
