/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Portal } from 'react-portal';

import { ClassNames } from 'helper';
import Styles from './modal.module.scss';

const { useState, useEffect } = React;

function Modal({
  actionbtn = true,
  actionBtnText,
  actionBtnStyle,
  showCloseBtn,
  actionBtnIcon,
  modalHeading,
  children,
  disabled,
  disableSave = true,
  cancelBtnText,
  saveBtnText,
  saveBtnFunction,
  cancelBtnFunction,
  size,
  type,
  direction,
  cancelBtn,
  // closeFunction,
  islibraryUpload,
  placeholderImage,
  placeholderfunction,
  path,
  getsignaturePath,
}) {
  // Use useEffect to add an event listener to the document
  const [isModalOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
    placeholderImage && placeholderfunction();
    if (type === 'material' && !isModalOpen) {
      getsignaturePath(path);
    }
  };
  useEffect(() => {
    placeholderImage && setModalIsOpen(true);
  }, []);
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        toggleModal();
      }
    }

    if (isModalOpen) {
      // Prevent scolling
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', onKeyDown);
    }

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const cancalFunction = () => {
    cancelBtnFunction();
    setModalIsOpen(!isModalOpen);
    placeholderImage && placeholderfunction();
  };

  const handleOnSubmit = () => {
    saveBtnFunction();
    // setModalIsOpen(false);
    if (islibraryUpload) {
      setModalIsOpen(false);
    }
  };
  return (
    <>
      {actionbtn && (
        <button
          className={[
            Styles.button,
            Styles.buttonAction,
            Styles[actionBtnStyle],
            disabled && Styles.disable,
          ].join(' ')}
          type="button"
          onClick={toggleModal}
        >
          {actionBtnIcon && <img src={actionBtnIcon} alt={actionBtnText} title={actionBtnText} />}
          <span>{actionBtnText && actionBtnText ? actionBtnText : 'show modal'}</span>
        </button>
      )}
      {isModalOpen && (
        <Portal>
          <div className={Styles.modal__backdrop} />
          <div className={Styles.modal__wrap}>
            <div className={Styles.modal__inner}>
              <div className={[Styles.modal__container, Styles[size], Styles[type]].join(' ')}>
                {showCloseBtn && (
                  <span aria-hidden onClick={toggleModal} className={Styles.closeBtn}>
                    X
                  </span>
                )}
                {modalHeading && <h3 className={Styles.modal__title}>{modalHeading}</h3>}
                <div className="modalContentWrap">
                  {type && type === 'fileuploader' ? (
                    <div className={[Styles.ModalContent, Styles.modal__uploader].join(' ')}>
                      {children}
                    </div>
                  ) : type === 'material' ? (
                    <div className={Styles.ModalContent}>{children}</div>
                  ) : (
                    <div className={Styles.ModalContent}>{children}</div>
                  )}
                </div>
                {type && type === 'material' ? (
                  ''
                ) : (
                  <div className={ClassNames([Styles.ModalFooter, Styles[direction]])}>
                    {cancelBtn && (
                      <button
                        className={[Styles.button, Styles.buttonCancel].join(' ')}
                        type="button"
                        onClick={
                          cancelBtnFunction && cancelBtnFunction ? cancalFunction : toggleModal
                        }
                      >
                        {cancelBtnText && cancelBtnText ? cancelBtnText : 'Cancel'}
                      </button>
                    )}
                    <button
                      className={[
                        disableSave && Styles.disable,
                        Styles.button,
                        Styles.buttonSave,
                      ].join(' ')}
                      type="button"
                      onClick={handleOnSubmit}
                    >
                      {saveBtnText}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}

export default Modal;
