/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'actions';
import { hasPermission, isEmpty } from 'helper';
import avatar from 'images/boyavatar.svg';
import upload from 'images/icon-upload.svg';
import Styles from './image.module.scss';

function ImageUploader({
  onChange,
  label,
  required,
  name,
  error,
  src,
  value,
  preview,
  permissionUrl,
  permissions,
  errorMessage,
  ...props
}) {
  const classes = [Styles.container].join(' ');

  const [image, setimage] = useState({ file: null, preview: src });
  const handleChange = ({ target }) => {
    if (target.files && target.files[0]) {
      setimage({
        file: target.files[0],
        preview: URL.createObjectURL(target.files[0]),
      });
      onChange({ target: { ...target, name, value: target.files[0] } });
    }
    target.value = '';
  };
  return (
    <>
      {isEmpty(permissionUrl) ||
      isEmpty(permissions) ||
      hasPermission(permissionUrl, permissions) ||
      hasPermission(permissionUrl, permissions) === false ||
      permissionUrl?.type === 'disabled' ? (
        <div
          className={[
            classes,
            hasPermission(permissionUrl, permissions) === false &&
              permissionUrl.type === 'disabled' &&
              Styles.disable,
          ].join(' ')}
        >
          {label && (
            <label htmlFor={name}>
              {label}
              {required && <span> *</span>}
            </label>
          )}
          <div className={Styles.imgContainer}>
            <input {...props} accept="image/*" name={name} type="file" onChange={handleChange} />
            {preview ? (
              <img src={image.preview || preview} alt="" />
            ) : name === 'placeholderImage' ? (
              <img src={upload} alt="" />
            ) : (
              <img src={avatar} alt="" />
            )}
          </div>
          {errorMessage && <span className={[Styles.error_text]}>{errorMessage}</span>}
          <span className={Styles.errortext}>{error}</span>
        </div>
      ) : null}
    </>
  );
}

ImageUploader.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  src: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPrimaryChange: PropTypes.func.isRequired,
  preview: PropTypes.string,
  permissionUrl: PropTypes.shape,
  permissions: PropTypes.shape.isRequired,
  required: PropTypes.bool,
};
ImageUploader.defaultProps = {
  name: '',
  label: 'Image',
  value: '',
  src: '',
  error: '',
  preview: '',
  errorMessage: '',
  required: false,
  permissionUrl: {},
};

const mapStateToProps = (state) => ({
  permissions: state.roleConfig.data,
});

export default connect(mapStateToProps, actions)(ImageUploader);
