/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Areachart from '@acodez/areachart';
import Table from '@acodez/table';
import { Tooltip } from '@acodez/tooltip';
import PropTypes from 'prop-types';

import actions from 'actions';
import Block from 'Components/Block';
import Cards from 'Components/Cards';
import DateRange from 'Components/DateRange';
import DropdownList from 'Components/DropdownList';
import DurationFilter from 'Components/DurationFilter';
import Notifications from 'Components/Notifications';
import Select from 'Components/Select';
import useToastContext from 'Components/ToastContextProvider/useToastContext';
// import EmptyCourse from "./EmptyCourse";
import Text from 'Components/Typography';
import { dateFormat, getActions, getDay, getMonth, imagePreview } from 'helper';
import iconNews from '../../../images/icon-news.svg';
import image from '../../../images/user.png';
import Styles from '../dashboard.module.scss';

import '@acodez/table/dist/index.css';

const headings = [
  { label: 'Exam Name', key: 'exam' },
  { label: 'Course Name', key: 'course' },
  { label: 'Enrollments', key: 'enrol', sortable: true },
  { label: 'Completions', key: 'completion', sortable: true },
];
// const data = [
//   {
//     course: 'Decimal into percent',
//     enrol: 24,
//     completion: '48%',
//   },
//   {
//     course: 'Percentages, Averages',
//     enrol: 49,
//     completion: '72%',
//   },
//   {
//     course: 'Animal Kingdom',
//     enrol: 10,
//     completion: '24%',
//   },
//   {
//     course: 'Brain Development',
//     enrol: 14,
//     completion: '98%',
//   },
// ];

function Dashboard({
  auth,
  newRegistartion,
  getData,
  recentExam,
  notitfication,
  summary,
  history,
}) {
  const addToast = useToastContext();

  useEffect(() => {
    const action = getActions('NEW_REGISTRATIONS');
    const action1 = getActions('RECENT_EXAM');
    const action2 = getActions('DASHBOARD_NOTIFICATION');
    const action3 = getActions('SUMMARY');
    getData('dashboard/newregistrations', action.GETONE, {
      fromDate: new Date(new Date().getFullYear(), 0, 1),
      toDate: new Date(new Date().getFullYear(), 11, 31),
    });
    getData('dashboard/recentexams', action1.GETONE);
    getData('dashboard/notifications', action2.GETONE);
    getData('dashboard/summary', action3.GETONE);
  }, []);

  // if (courses.length > 0) return <div>Dashboard</div>;
  const [filter, setfilter] = useState([
    { label: 'Daily', isactive: true },
    { label: 'Weekly', isactive: false },
    { label: 'Monthly', isactive: false },
  ]);

  const days = newRegistartion?.data?.[0]?.daily
    ?.reverse()
    ?.map((val) => getDay(val?._id?.month, val?._id?.day));

  const daysData = newRegistartion?.data?.[0]?.daily?.reverse()?.map((val) => val?.newRegistration);

  const week = newRegistartion?.data?.[0]?.weekly
    ?.reverse()
    ?.map((val) => `week ${val?._id?.week}`);

  const weekData = newRegistartion?.data?.[0]?.weekly
    ?.reverse()
    ?.map((val) => val?.newRegistration);

  const month = newRegistartion?.data?.[0]?.monthly
    ?.reverse()
    ?.map((val) => getMonth(val?._id?.month));

  const monthData = newRegistartion?.data?.[0]?.monthly
    ?.reverse()
    ?.map((val) => val?.newRegistration);

  const [axis, setaxis] = useState({ xaxis: [], yaxis: [] });

  const data = recentExam?.data?.map((val) => ({
    exam: val?.title,
    course: val?.course,
    enrol: val?.totalEnrollment,
    completion: `${((val?.totalCompleted / val?.totalEnrollment) * 100)?.toFixed(1)}%`,
  }));

  const handleViewDetails = (id, type) => {
    switch (type) {
      case 'courseEnrolled':
        history.push(`/students/${id}/course`);
        break;
      case 'newUser':
        history.push(`/students/${id}/analytics`);
        break;
      case 'review':
        history.push(`/students/${id}/reviews`);
        break;
      case 'courseSubscribed':
        history.push(`/students/${id}/subscription`);
        break;
      default:
        history.push(`/students/${id}/analytics`);
        break;
    }
  };

  const item = notitfication?.data?.map((val) => ({
    id: val._id,
    title: val?.students?.name ? val?.students?.name : '______',
    desc: val?.content?.title,
    image: imagePreview(
      val?.students?.mediaStorageType,
      val?.students?.profileImageName,
      val?.students?.profileImagePath
    ),
    // time: new Date(val?.createdAt).toLocaleDateString()?.slice(0, 5),
    time: dateFormat(val?.createdAt),
    handleOnClick: handleViewDetails,
    type: val?.type,
    studentId: val?.students?._id,
  }));

  const items = item?.slice(0, 5);

  const totalRegistration = summary?.data?.[0]?.totalRegistration?.[0]?.total;

  const registartionXaxis = summary?.data?.[0]?.registrationSummary?.map((val) =>
    getDay(val?._id?.month, val?._id?.day)
  );

  const registrationYaxis = summary?.data?.[0]?.registrationSummary?.map((val) => val?.total);

  const activeStudents = summary?.data?.[0]?.activeStudents?.[0]?.total;

  const activeXaxis = summary?.data?.[0]?.activeStudentSummary?.map((val) =>
    getDay(val?._id?.month, val?._id?.day)
  );

  const activeYaxis = summary?.data?.[0]?.activeStudentSummary?.map((val) => val?.total);

  const courseCompletion = summary?.data?.[0]?.completedPercentage[0]?.percentage;

  const completeXaxis = summary?.data?.[0]?.completedPercentageSummary?.map((val) =>
    getDay(val?._id?.month, val?._id?.day)
  );

  const completeYaxis = summary?.data?.[0]?.completedPercentageSummary?.map(
    (val) => val?.percentage
  );
  useEffect(() => {
    setaxis({ ...axis, xaxis: days, yaxis: daysData });
  }, [newRegistartion?.data]);

  const onhandleClick = (labels) => {
    if (labels === 'Daily') {
      setaxis({ ...axis, xaxis: days, yaxis: daysData });
    } else if (labels === 'Weekly') {
      setaxis({ ...axis, xaxis: week, yaxis: weekData });
    } else if (labels === 'Monthly') {
      setaxis({ ...axis, xaxis: month, yaxis: monthData });
    }
    const filterdata = filter.map(({ label, isactive }) => {
      if (label === labels) {
        return {
          label,
          isactive: !isactive,
        };
      }

      return {
        label,
        isactive: false,
      };
    });
    setfilter(filterdata);
  };

  const dateChange = (fromDate, toDate) => {
    const action = getActions('NEW_REGISTRATIONS');
    getData('dashboard/newregistrations', action.GETONE, { fromDate, toDate });
  };

  return (
    <div className="mainContent">
      {newRegistartion && summary && (
        <>
          <div className="pageTitleSec pb-30">
            <div>
              <Text
                type="h1"
                onClick={() => addToast({ message: 'Hello', theme: 'success' })}
              >{`Hi ${auth?.user?.name}`}</Text>
              <p
                aria-hidden
                className="pageTitleDesc"
                onClick={() => addToast({ message: 'Hi', theme: 'success' })}
              >
                Here are your stats for today.
              </p>
            </div>
            <div className="pageTitleRight">
              <div className={Styles.notification}>
                <Tooltip text="Notification" position="left">
                  <img
                    style={{ cursor: 'pointer' }}
                    src={iconNews}
                    alt="updates"
                    aria-hidden
                    onClick={() => history.push('/dashboard/notification')}
                  />
                </Tooltip>
              </div>
              <DropdownList
                togglerImg={iconNews}
                notification
                title="Recent Notification"
                link="/"
                items={[
                  {
                    id: 1,
                    title: 'Phillip Rosser',
                    desc: 'Joined',
                    image,
                    time: '8h',
                  },
                  {
                    id: 2,
                    title: 'Ryan Vaccaro',
                    desc: 'Reviewed a course',
                    image,
                    time: '10h',
                  },
                  {
                    id: 3,
                    title: 'Cristofer Torff',
                    desc: 'Joined',
                    image,
                    time: '12h',
                  },
                  {
                    id: 4,
                    title: 'Click to read the latest updates we have for you',
                    type: 'announcement',
                    image,
                    time: '10h',
                  },
                  {
                    id: 5,
                    title: 'Cristofer Torff',
                    desc: 'Joined',
                    image,
                    time: '12h',
                  },
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-70">
              <Block width="max" size="sm">
                <div className={Styles.filter_area}>
                  <div className={Styles.filter_left}>
                    {/* <Select
                      color="border"
                      size="md"
                      placeholder="New Registrations"
                      options={[{ value: 'New Registrations' }]}
                    /> */}
                    <h3>New Registrations</h3>
                  </div>
                  <div className={Styles.filter_right}>
                    <DateRange color="secondary" onChangeDate={dateChange} />
                    <DurationFilter padding="pl" handleClick={onhandleClick} tabs={filter} />
                  </div>
                </div>
                <Areachart
                  color="#007BFF"
                  xaxisdata={axis.xaxis}
                  yaxisdata={axis.yaxis}
                  height="340px"
                />
              </Block>

              <div className="dashTableWrapper">
                <Block width="max" size="nopadding">
                  <h3>Recent Exams</h3>
                  <Table headings={headings} rows={data} size="medium" />
                </Block>
              </div>
            </div>
            <div className="col-30">
              <Block size="md" width="max">
                <Notifications
                  title="Recent Notification"
                  link="/dashboard/notification"
                  linkText="View all"
                  linkPosition="right"
                  items={items}
                />
              </Block>

              <Cards
                gap="10"
                items={[
                  {
                    id: 1,
                    title: totalRegistration,
                    desc: 'New Sign ups',
                    itemStyle: 'counter',
                    graph: 'true',
                    color: '#6FCF97',
                    xaxisdata: registartionXaxis,
                    yaxisdata: registrationYaxis,
                  },
                  {
                    id: 2,
                    title: activeStudents,
                    desc: 'Active Students',
                    itemStyle: 'counter',
                    graph: 'true',
                    color: '#007BFF',
                    xaxisdata: activeXaxis,
                    yaxisdata: activeYaxis,
                  },
                  {
                    id: 3,
                    title: courseCompletion?.toFixed(1),
                    desc: 'Course Completion',
                    itemStyle: 'counter',
                    graph: 'true',
                    color: '#F2994A',
                    xaxisdata: completeXaxis,
                    yaxisdata: completeYaxis,
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );

  // else case - commented out now
  // return <EmptyCourse />;
}

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  newRegistartion: state.newRegistrations,
  recentExam: state.recentExam,
  notitfication: state.dashboardNotification,
  summary: state.summary,
});
Dashboard.propTypes = {
  auth: PropTypes.shape.isRequired,
  newRegistartion: PropTypes.shape,
  recentExam: PropTypes.shape,
  notitfication: PropTypes.shape,
  summary: PropTypes.shape,
  getData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

Dashboard.defaultProps = {
  newRegistartion: {},
  recentExam: {},
  notitfication: {},
  summary: {},
  history: {},
};

export default withRouter(connect(mapStateToProps, actions)(Dashboard));
