import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'actions';
import { getActions, getCurrencySymbol } from 'helper';

function Currency({ getData, value, general }) {
  const action = getActions('GENERAL');
  useEffect(() => {
    getData('settings/general', action.GETONE);
  }, []);
  return (
    <div>
      <span
        style={{
          'font-weight': 200,
          'font-size': '14px',
          'line-height': '18px',
          color: '#101f4f',
        }}
      >
        {getCurrencySymbol(general?.data?.configuration.currency)}
        {value}
      </span>
    </div>
  );
}

const mapStateToProps = (state) => ({
  general: state.general_details,
});
Currency.propTypes = {
  getData: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  general: PropTypes.shape.isRequired,
};
export default connect(mapStateToProps, actions)(Currency);
