import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Notifications from 'Components/Notifications';
import dropIcon from '../../images/icon-drop.svg';
import Styles from './dropdownlist.module.scss';

function DropdownList({ toggler, items, notification, title, url, onClick }) {
  const [dropdown, setDropdown] = useState('false');
  const onItemClick = (e, val) => {
    // onClick(e.target.name, e.target.value);
    setDropdown('false');
    document.getElementsByClassName('togglerText')[0].innerHTML = e.target.innerHTML;
    onClick(val);
  };
  return (
    <div className={Styles.dropdownListWrap}>
      <div className={Styles.dropdownToggler}>
        <span
          aria-hidden
          onClick={() => {
            if (dropdown === 'false') {
              setDropdown('true');
            } else {
              setDropdown('false');
            }
          }}
        >
          {/* {togglerImg && <img src={togglerImg} alt="Images" className={Styles.togglerImg} />} */}
          {toggler && <i className="togglerText">{toggler}</i>}
          {notification ? (
            ''
          ) : (
            <>
              {dropdown === 'false' ? (
                <img className={Styles.dropShow} src={dropIcon} alt="drop" />
              ) : (
                <img className={Styles.dropHide} src={dropIcon} alt="drop" />
              )}
            </>
          )}
        </span>
      </div>

      {dropdown === 'true' ? (
        <ul className={Styles.drodownList}>
          {notification ? (
            <div className={Styles.noti_area}>
              <Notifications
                title={title}
                link={url}
                linkText="View all"
                linkPosition="bottom"
                items={items}
              />
            </div>
          ) : (
            <>
              {items.map((item) => (
                <DropdownListItem
                  onClick={(event) => onItemClick(event, item.id)}
                  key={item.id}
                  title={item.title}
                  value={item.value}
                />
              ))}
            </>
          )}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
}

function DropdownListItem({ title, value, onClick }) {
  return (
    <li aria-hidden onClick={onClick} data-value={value}>
      {title}
    </li>
  );
}
DropdownList.propTypes = {
  toggler: PropTypes.string,
  items: PropTypes.arrayOf(),
  // togglerImg: PropTypes.string,
  notification: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
};

DropdownList.defaultProps = {
  toggler: '',
  title: '',
  url: '',
  items: [],
  // togglerImg: [],
  notification: false,
  onClick: () => {},
};

DropdownListItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.string,
};
DropdownListItem.defaultProps = {
  title: '',
  value: '',
  onClick: () => {},
};

export default DropdownList;
