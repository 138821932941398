import React, { useEffect, useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import PropTypes from 'prop-types';

import { ClassNames } from 'helper';
import Styles from './daterange.module.scss';

export default function DateRange({ size, color, label, requiredText, onChangeDate }) {
  const [value, onChange] = useState([
    new Date(new Date().getFullYear(), 0, 1),
    new Date(new Date().getFullYear(), 11, 31),
  ]);

  useEffect(() => {
    if (value) {
      onChangeDate(value[0], value[1]);
    }
  }, [value]);
  return (
    <div className={Styles.field_area}>
      {label && (
        <label className={[Styles.label_text]}>
          {label}
          {requiredText && <span>{requiredText}</span>}
        </label>
      )}
      <DateRangePicker
        onChange={onChange}
        value={value}
        className={ClassNames([Styles.field_box, Styles[size], Styles[color]])}
        format="dd MMM yyyy"
        calendarIcon=""
        clearIcon=""
      />
    </div>
  );
}

DateRange.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  requiredText: PropTypes.bool,
  onChangeDate: PropTypes.func.isRequired,
};

DateRange.defaultProps = {
  size: '',
  color: 'primary',
  label: '',
  requiredText: false,
};
