import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

import Styles from './infinitescroller.module.scss';

export default function InfiniteScroller({ dataLength, next, loading, children }) {
  //   const [state, setstate] = useState({items: Array.from({ length: 20 })})

  // const style = {
  //   height: 30,
  //   border: "1px solid green",
  //   margin: 6,
  //   padding: 8
  // };
  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      next();
      // setstate({
      //   items: state.items.concat(Array.from({ length: 20 }))
      // });
    }, 1000);
  };
  return (
    // <div id="scrollableDiv">
    <InfiniteScroll
      // scrollableTarget="scrollableDiv"
      dataLength={dataLength}
      next={fetchMoreData}
      hasMore
      loader={loading ? <h4 className={Styles.loading}>Loading...</h4> : ''}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {children}
      {/* {state.items.map((i, index) => (
            <div style={style} key={index}>
              div - #{index}
            </div>
          ))} */}
    </InfiniteScroll>
    // </div>
  );
}

InfiniteScroller.propTypes = {
  loading: PropTypes.bool.isRequired,
  dataLength: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
