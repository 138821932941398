import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MyErrorBoundary extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error };
  }

  componentDidCatch() {
    // Log the error to an error reporting service
  }

  render() {
    const { error } = this.state;
    if (error) {
      // You can render any custom fallback UI
      return (
        <div className="errorScreen">
          <p>Something broke</p>
          <p>{error?.message}</p>
        </div>
      );
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

MyErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};
