import React, { useState } from 'react';
import { connect } from 'react-redux';
import Carousel from '@brainhubeu/react-carousel';
import PropTypes from 'prop-types';

import { forgetPassword } from 'actions/auth';
import { FORGOT_FIELDS } from 'config/fields';
import { emailTrim, isEmpty } from 'helper';
import { validate } from 'helper/validation';
import Button from '../../Components/Button';
import FormBuilder from '../../Components/FormBuilder';
import loginPic from '../../images/login-pic.png';
import logoImageLight from '../../images/logolight.svg';
import Styles from './forgotpassword.module.scss';

import '@brainhubeu/react-carousel/lib/style.css';

function ForgotPassword(props) {
  const { forgetPassword } = props;
  const [data, setdata] = useState({});
  const [errors, seterrors] = useState({});
  const [loading, setLoading] = useState(false);

  const onformchange = (name, value) => {
    setdata({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const error = validate(data, FORGOT_FIELDS);
    if (isEmpty(error)) {
      forgetPassword(emailTrim(data.email), (err) => {
        seterrors(err);
        setLoading(false);
      });
      //  addToast({message: 'Please check your mail', theme: 'success'});
    } else {
      seterrors(error);
      setLoading(false);
    }
  };
  return (
    <div>
      <div className={Styles.loginPage}>
        <div className={Styles.loginContent}>
          <div className={Styles.loginLogo}>
            <img src={logoImageLight} alt="logo" />
          </div>
          <div className={Styles.loginForm}>
            <div className={Styles.loginFormHead}>
              <h2>Need a new password?</h2>
              <p>
                Please provide your email address and we will send you instructions on how to change
                your password.
              </p>
            </div>
            <FormBuilder
              errors={errors}
              formdata={data}
              onChange={onformchange}
              fields={[
                {
                  name: 'email',
                  label: 'Email',
                  type: 'email',
                  required: 'required',
                  placeholder: 'email',
                  size: 'big',
                },
                {
                  name: 'submit',
                  children: 'Get a new Password',
                  type: 'submit',
                  label: 'submit',
                  btnSize: 'xl',
                  btnWidth: 'full',
                  loading,
                  onClick: handleSubmit,
                },
              ]}
            />
            <div className="backToLogin">
              <Button type="button" link="/login" btnStyle="transparent">
                Login
              </Button>
            </div>
          </div>
        </div>
        <div className={Styles.loginCarousel}>
          <img className={Styles.loginLeftImage} src={loginPic} alt="login" />
          <div className={Styles.carouselWrap}>
            <Carousel dots autoPlay="3000" infinite>
              <div>
                <h2>1 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>
                  ac ornare risus justo eget nisl. Aenean elit magna, vestibulum sed quam sit amet,
                  sagittis pharetra tellus. Nunc rhoncus, nisl quis ultricies tempor
                </p>
              </div>
              <div>
                <h2>2 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>aca rhoncus, nisl quis ultricies tempor</p>
              </div>
              <div>
                <h2>3 Vivamus auctor, tellus eu viverra sodale</h2>
                <p>
                  ac ornare risus justo eget nisl. Aenean elit magna, vestibulum sed quam sit amet,
                  sagittis pharetra tellus. Nunc rhoncus, nisl quis ultricies tempor
                </p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});

ForgotPassword.propTypes = {
  forgetPassword: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { forgetPassword })(ForgotPassword);
