import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getTimePickerValue } from 'helper';
import Styles from './timepicker.module.scss';

function TimePicker({
  type,
  label,
  onChange,
  required,
  defaultValue,
  requiredText,
  errorMessage,
  radius,
  border,
  size,
  margin,
  value,
  ...rest
}) {
  const ontextchange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <div className={[Styles.inputWrap, Styles[margin], Styles[size]].join(' ')}>
      {label && (
        <label className={[Styles.label_text]}>
          {label}
          {requiredText && <span>{requiredText}</span>}
        </label>
      )}
      <input
        value={moment(value).isValid() ? getTimePickerValue(value) : value}
        defaultValue={defaultValue}
        onChange={ontextchange}
        type={type}
        className={[
          Styles.formField,
          errorMessage && Styles.error,
          Styles[radius],
          Styles[border],
          Styles[size],
        ].join(' ')}
        {...rest}
      />
      {errorMessage && <span className={[Styles.error_text]}>{errorMessage}</span>}
    </div>
  );
}

export default TimePicker;
TimePicker.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  requiredText: PropTypes.string,
  errorMessage: PropTypes.string,
  rows: PropTypes.number,
  radius: PropTypes.string,
  border: PropTypes.string,
  size: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string.isRequired,
  margin: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
TimePicker.defaultProps = {
  radius: 'round',
  border: 'none',
  size: 'normal',
  type: '',
  label: '',
  required: false,
  requiredText: '',
  errorMessage: '',
  rows: 0,
  defaultValue: '',
  margin: '',
};
